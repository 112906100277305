import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import emailjs from 'emailjs-com';
import ThankYou from '../views/ThankYou';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../assets/svgs/arrowRight.svg';
import { motion } from 'framer-motion';
import AnimatedDiv from '../components/AnimatedDiv';
import { postContactFormDetails } from '../apis/contactFormApi';
import { logEvent } from '../analytics';

const apiKey = process.env.REACT_APP_MAILJS_USER_ID;
const serviceKey = process.env.REACT_APP_MAILJS_SERVICE_ID;
const templateKey = process.env.REACT_APP_MAILJS_TEMPLATE_ID;
// emailjs initialised with user ID
emailjs.init(apiKey);
export default function ContactForm() {
  const location = useLocation();
  const navigate = useNavigate();
  // const { additionalProp } = location.state || {};
  // console.log("Additional props from Request demo: ", additionalProp);
  // this is all the options for intereset field
  const options = [
    { value: 'Request a demo', label: 'Request a demo' },
    { value: 'General enquiries', label: 'General enquiries' },
    { value: 'Prospective investment', label: 'Prospective investment' },
    { value: 'Partnership', label: 'Partnership' },
    { value: 'Customer support', label: 'Customer support' },
    { value: 'Joining our team', label: 'Joining our team' },
  ];
  // this is states for all the fields
  const [userInterest, setUserInterest] = useState('');
  const [userSubject, setUserSubject] = useState('');
  const [userMessage, setUserMessgae] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userCompany, setUserCompany] = useState('');
  const [userAggreeTerms, setUserAggreeTerms] = useState('notAgreed');
  const [startSpinning, setStartSpinning] = useState(false);
  // for the form errors
  const [formErrors, setFormErrors] = useState([]);
  // for the form errors
  const [formTypingState, setTypingState] = useState(false);
  // form submission state
  const [isSubmitted, setFormStatus] = useState(false);
  // for error after form submission
  const [isErrorOccured, setIsErrorOccured] = useState(false);

  const [isUserAgreeToCommunication, setIsUserAgreeToCommunication] =
    useState(false);

  useEffect(() => {
    const { additionalProp } = location.state || {};
    // console.log("Additional props from Request demo: ", additionalProp);
    if (additionalProp) {
      setUserInterest({ value: 'Request a demo', label: 'Request a demo' });
      setUserSubject('Demo Request');
    }
  }, []);

  const handleFocus = (event) => {
    setTypingState(event.target.name);
  };
  const handleBlur = () => {
    setTypingState(false);
  };
  //handling change on input field
  const handleMessage = (event) => {
    setUserMessgae(event.target.value);
  };
  const handleSubject = (event) => {
    setUserSubject(event.target.value);
  };
  const handleFirstName = (event) => {
    setUserFirstName(event.target.value);
  };
  const handleLastName = (event) => {
    setUserLastName(event.target.value);
  };
  const handleEmail = (event) => {
    setUserEmail(event.target.value);
  };
  const handleCompany = (event) => {
    setUserCompany(event.target.value);
  };
  const handleAgreeTerms = (event) => {
    // setUserAggreeTerms(event.target.value)
    if (isUserAgreeToCommunication) setUserAggreeTerms('notAgreed');
    else setUserAggreeTerms('agreed');
    setIsUserAgreeToCommunication(!isUserAgreeToCommunication);
  };
  const handleUserInterest = (event) => {
    setUserInterest(event);
    if (event.value === 'Request a demo') {
      setUserSubject('Demo Request');
    } else if (userSubject === 'Demo Request') {
      setUserSubject('');
    }
  };
  const customStylesForSelect = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '0' : '0',
      outline: state.isFocused ? '0' : '0',
      boxShadow: state.isFocused ? 'none' : null,
      color: state.isSelected ? '#ffffff' : '#ffffff',
      '::before': {
        marginLeft: '10px',
        marginRight: '5px',
        content: '"\\f078"',
        fontFamily: 'FontAwesome',
        fontWeight: 900,
        fontStyle: 'normal',
        transition: 'all 0.5s',
        rotate: state.menuIsOpen ? '180deg' : '360deg',
      },
      ':hover': {
        cursor: 'pointer',
      },
    }),
    container: (provided) => ({
      ...provided,
      border: '0.5px solid',
      borderColor: formErrors.userInterest ? '#C92D2D;' : 'white',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '1px',
      borderTop: 'none',
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: 'black',
      border: '1px solid white',
      borderTop: '0',
      paddingTop: '0',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'black',
      color: state.isSelected ? '#ffffff' : '#ffffff',
      borderTop: '1px solid white',
      padding: '32px',
      paddingTop: '8px',
      paddingBottom: '8px',
      ':hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(255,255,255,0.2)',
      },
      ':first-of-type': {
        borderTop: 0,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'white',
      opacity: '60%',
    }),
  };
  // send email function and parameter setup for emailJS
  async function sendEmail(email, message, interest, firstname, lastname, to) {
    // email template variable
    const templateParams = {
      to_mail: to,
      from_email: email,
      message: message,
      subject: interest,
      firstname: firstname,
      lastname: lastname,
    };

    let isFormSubmissionFailed = false;

    await emailjs.send(serviceKey, templateKey, templateParams).then(
      (result) => {
        setStartSpinning(false);
        setIsErrorOccured(false);
        // setFormStatus(true);

        // Reset form fields after submission
        setUserInterest('');
        setUserMessgae('');
        setUserFirstName('');
        setUserLastName('');
        setUserEmail('');
        setUserAggreeTerms('notAgreed');
        setUserCompany('');
        setUserSubject('');
        setIsUserAgreeToCommunication(false);
        // navigate('/thank-you', { state: { from: 'contact-page' } });

        // console.log('Email sent successfully:', result.text);
      },
      (error) => {
        setIsErrorOccured(true);
        isFormSubmissionFailed = true;
        setStartSpinning(false);
        console.error('Email failed to send:', error);
      }
    );

    if (userInterest.value === 'Request a demo') {
      const data = {
        firstName: userFirstName,
        lastName: userLastName,
        company: userCompany,
        email: userEmail,
        message: userMessage,
      };

      try {
        await postContactFormDetails(data);
        // Optionally, you can handle success here if needed
      } catch (error) {
        // Handle error if necessary
        isFormSubmissionFailed = true;
        setIsErrorOccured(true);
        setStartSpinning(false);
        console.error('Error submitting contact form:', error);
      }
    }

    if (!isFormSubmissionFailed) {
      // Navigate only if no error occurred
      setFormStatus(true);
      navigate('/thank-you', {
        state: { from: 'contact-page', additionalProp: userInterest },
      });
    }
  }
  // handle submit on click
  const handleSubmit = async (e) => {
    logEvent(
      'User Engagement',
      'Submit Button Clicked',
      'Contact Form Submission'
    );

    e.preventDefault();
    let errors = {};
    let sendEmailTo = '';
    // this will determine where to send email based on the selected interest
    if (userInterest.value === 'General enquiries') {
      sendEmailTo = 'contact@clearly.earth';
    }
    if (userInterest.value === 'Prospective investment') {
      sendEmailTo = 'investor@clearly.earth';
    }
    if (userInterest.value === 'Partnership') {
      sendEmailTo = 'partner@clearly.earth';
    }
    if (userInterest.value === 'Customer support') {
      sendEmailTo = 'support@clearly.earth';
    }
    if (userInterest.value === 'Joining our team') {
      sendEmailTo = 'contact@clearly.earth';
    }
    if (userInterest.value === 'Request a demo') {
      sendEmailTo = 'contact@clearly.earth';
    }
    // Check for errors in each field
    if (!userInterest) {
      errors.userInterest = 'Please select an interest';
    }
    if (!userSubject) {
      errors.userSubject = 'Please enter a subject';
    }
    if (!userMessage && userInterest.value !== 'Request a demo') {
      errors.userMessage = 'Please enter a message';
    }
    if (!userFirstName) {
      errors.userFirstName = 'Please enter your first name';
    }
    if (!userLastName) {
      errors.userLastName = 'Please enter your last name';
    }
    if (userInterest.value === 'Request a demo' && !userCompany) {
      errors.userCompany = 'Please enter your company name';
    }
    if (!userEmail) {
      errors.userEmail = 'Please enter your email';
    } else if (!/\S+@\S+\.\S+/.test(userEmail)) {
      errors.userEmail = 'Please enter a valid email';
    }
    if (userAggreeTerms !== 'agreed') {
      errors.userAggreeTerms =
        'Please agree to receive communications from Clearly';
    }
    // Check if there are any errors
    if (Object.keys(errors).length === 0) {
      // start spinning
      setStartSpinning(true);

      // send email
      sendEmail(
        userEmail,
        userMessage,
        userSubject,
        userFirstName,
        userLastName,
        sendEmailTo
      );
    } else {
      setFormStatus(false);
    }
    setFormErrors(errors);
  };
  return !isSubmitted ? (
    <motion.div
      className="pure-g container contact-us-page"
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="pure-u-xl-8-24 page-title">
        <AnimatedDiv>
          <h1>Contact us</h1>
        </AnimatedDiv>
      </div>
      <div className="pure-u-xl-16-24 form-main">
        <AnimatedDiv>
          <div name="contactForm" className="contact-form-container pure-g">
            <div className="pure-u-xl-12-24">
              <div className="pure-u-1">
                <fieldset className="selectInterest">
                  <label>
                    Select your interest<span>*</span>
                  </label>
                  <Select
                    className="selectInterestField"
                    name="interest"
                    placeholder="Select from dropdown items"
                    styles={customStylesForSelect}
                    options={options}
                    isSearchable={false}
                    value={userInterest}
                    onChange={handleUserInterest}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                  {formErrors.userInterest && (
                    <span className="error">{formErrors.userInterest}</span>
                  )}
                </fieldset>
                <fieldset className="inputSubject">
                  <label>
                    Subject of query<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="subject"
                    autoComplete="off"
                    className={
                      formTypingState === 'subject'
                        ? 'active-field'
                        : formErrors.userSubject
                        ? 'error-field'
                        : 'default'
                    }
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Type..."
                    value={userSubject}
                    onChange={handleSubject}
                  ></input>
                  {formErrors.userSubject && (
                    <span className="error">{formErrors.userSubject}</span>
                  )}
                </fieldset>
                <fieldset className="inputMessage">
                  <label>
                    Your message
                    {userInterest?.value !== 'Request a demo' && <span>*</span>}
                  </label>
                  <textarea
                    type="text"
                    name="message"
                    className={
                      formTypingState === 'message'
                        ? 'active-field'
                        : formErrors.userMessage
                        ? 'error-field'
                        : 'default'
                    }
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Type..."
                    value={userMessage}
                    onChange={handleMessage}
                  ></textarea>
                  {formErrors.userMessage && (
                    <span className="error">{formErrors.userMessage}</span>
                  )}
                </fieldset>
              </div>
            </div>
            <div className="pure-u-xl-12-24">
              <div className="pure-u-1">
                <fieldset className="inputFname">
                  <label>
                    First name<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="fname"
                    autoComplete="off"
                    className={
                      formTypingState === 'fname'
                        ? 'active-field'
                        : formErrors.userFirstName
                        ? 'error-field'
                        : 'default'
                    }
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Type..."
                    value={userFirstName}
                    onChange={handleFirstName}
                  ></input>
                  {formErrors.userFirstName && (
                    <span className="error">{formErrors.userFirstName}</span>
                  )}
                </fieldset>
                <fieldset className="inputLname">
                  <label>
                    Last name<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    autoComplete="off"
                    className={
                      formTypingState === 'lname'
                        ? 'active-field'
                        : formErrors.userLastName
                        ? 'error-field'
                        : 'default'
                    }
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Type..."
                    value={userLastName}
                    onChange={handleLastName}
                  ></input>
                  {formErrors.userLastName && (
                    <span className="error">{formErrors.userLastName}</span>
                  )}
                </fieldset>
                <fieldset className="inputEmail">
                  <label>
                    Email<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    autoComplete="off"
                    className={
                      formTypingState === 'email'
                        ? 'active-field'
                        : formErrors.userEmail
                        ? 'error-field'
                        : 'default'
                    }
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Type..."
                    value={userEmail}
                    onChange={handleEmail}
                  ></input>
                  {formErrors.userEmail && (
                    <span className="error">{formErrors.userEmail}</span>
                  )}
                </fieldset>
                <fieldset className="inputCompany">
                  <label>
                    Company
                    {userInterest?.value === 'Request a demo' && <span>*</span>}
                  </label>
                  <input
                    type="text"
                    name="company"
                    autoComplete="off"
                    className={
                      formTypingState === 'email'
                        ? 'active-field'
                        : formErrors.userCompany
                        ? 'error-field'
                        : 'default'
                    }
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="Type..."
                    value={userCompany}
                    onChange={handleCompany}
                  ></input>
                  {formErrors.userCompany && (
                    <span className="error">{formErrors.userCompany}</span>
                  )}
                </fieldset>
                <fieldset className="selectTerms">
                  <input
                    type="checkbox"
                    id="agreeTerms"
                    name="agreeTerms"
                    value={isUserAgreeToCommunication}
                    checked={isUserAgreeToCommunication}
                    onChange={handleAgreeTerms}
                  ></input>
                  <label
                    htmlFor="agreeTerms"
                    className={formErrors.userAggreeTerms ? 'error' : 'agreed'}
                    checked={userAggreeTerms}
                  >
                    I agree to receive communications from Clearly<span>*</span>
                  </label>
                </fieldset>
                <div className="submision-button-container pure-u-1">
                  <div className="pure-u-xl-10-24">
                    {!startSpinning && (
                      <fieldset className="submitForm buttonNavigator">
                        <button type="submit" onClick={handleSubmit}>
                          Submit
                        </button>
                        <ArrowRight />
                      </fieldset>
                    )}

                    {startSpinning && <span className="loader"></span>}
                  </div>
                  {isErrorOccured && (
                    <div className="submissionError">
                      <div>There was an error with your submission.</div>
                      <div>
                        {' '}
                        Please try again later or email us directly at
                        contact@clearly.earth{' '}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </AnimatedDiv>
      </div>
    </motion.div>
  ) : (
    <ThankYou />
  );
}
