import React, { useEffect, useState } from 'react'
function Showcase(props) {
    const showcaeListName = props.className;
    const backgroundColor = props.backgroundColor;
    const styleBackground = {
        "backgroundColor": backgroundColor
    }
    const [showcaseLogos, setLogos] = useState([]);
    useEffect(() => {
        fetch('./datasets/showcase.json')
            .then(response => response.json())
            .then(data => setLogos(data.logos));
    }, []);
    return (
        <>
            <div className={'container showcase-list ' + showcaeListName} style={styleBackground}>
                <div className='title-container'>
                    <h4>{showcaeListName}</h4>
                </div>
                <div className='logo-list'>
                    <ul className='pure-g'>
                        {showcaseLogos.map((logo) => (
                            logo.category === showcaeListName && (
                                <li key={logo.name} >
                                    <a href={logo.link} target='blank'>
                                        <img src={logo.image} alt={logo.name} />
                                    </a>
                                </li>
                            )
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}
export default Showcase