const ga = {
    allowTracking() {
        function gtag() { window.dataLayer.push(arguments); }
        gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted'
        });
    }
}

export default ga;