const YouTubeVideo = ({ blok }) => {

    const url = blok.url
        .replace("https://youtu.be/", "https://www.youtube.com/embed/")
        .replace("watch?v=", "embed/");

    return (
        <div className="tw-flex tw-justify-center tw-w-full">
            <iframe 
                className="tw-aspect-video"
                width="100%"
                src={url} 
                title={`Vimeo video player - ${url}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerPolicy="strict-origin-when-cross-origin" 
                allowFullScreen={true}>
            </iframe>
        </div>
    )
}

export default YouTubeVideo;