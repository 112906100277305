import { StoryblokComponent } from '@storyblok/react';
import { MARK_LINK, NODE_IMAGE, NODE_PARAGRAPH, render } from 'storyblok-rich-text-react-renderer';
import Tag from '../../components/Blogs/Tag';
import TagGroup from '../../components/Blogs/TagGroup';
import BlogDate from '../../components/Blogs/BlogDate';
import { ReactComponent as ArrowRight } from '../../assets/svgs/arrowRight.svg'

const BackToBlogs = () => {
    return (
        <a href="/blog" className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
            <div className="tw-rotate-[225deg] tw-scale-75"><ArrowRight /></div>
            <p className="tw-text-white tw-font-thin tw-mt-1 tw-text-lg">Back to blogs</p>
        </a>
    )
}

const Banner = ({ blok }) => {
    return (
        <div className="w-full tw-flex tw-flex-col tw-gap-2">
            <div className="tw-flex tw-flex-row tw-w-full tw-align-middle-center">
                <div className="tw-w-full tw-overflow-hidden tw-max-h-80">
                    <img className="tw-object-cover tw-w-full tw-max-h-full" src={`${blok.hero_image.filename}/m/`} alt={blok.hero_image.alt} />
                </div>
            </div>
            {
                blok.hero_image.name &&
                <p className="tw-text-sm tw-italic tw-text-gray-300">{blok.hero_image.name}</p>
            }
        </div>
    )
}

const BlogPost = ({ blok, created_at, published_at }) => {

    const blogDate = published_at ? new Date(published_at) : new Date(created_at);

    return (
        <div className="tw-w-full tw-max-w-[800px]">
            <div className="tw-flex tw-flex-col tw-gap-6 tw-items-stretch">
                <BackToBlogs />
                <Banner blok={blok} />
                <div className="tw-flex tw-flex-row tw-justify-between tw-align-top tw-gap-4 tw-flex-nowrap">
                    <TagGroup>
                        {
                            (blok.tags && blok.tags.replace(' ', '')) &&
                            blok.tags.split(' ').map(tag => (
                                <Tag key={tag} text={tag} />
                            ))
                        }
                    </TagGroup>
                    <BlogDate date={blogDate} />
                </div>
                <div className="tw-flex tw-flex-col">
                    <h1 className="tw-text-3xl">{blok.title}</h1>
                    <h2 className="tw-text-2xl tw-font-extralight">{blok.headline}</h2>
                </div>
                <div className="blog-post">
                    {
                        render(blok.content, {
                            defaultBlokResolver: (name, props) => {
                                const blok = { ...props, component: name };
                                return <StoryblokComponent blok={blok} key={props._uid} />;
                            },
                            nodeResolvers: {
                                [NODE_PARAGRAPH]: (children) => {
                                    return <p className="[&:not(:last-child)]:tw-mb-6">{children}</p>;
                                },
                                [NODE_IMAGE]: (_, { src, alt, title }) => {
                                    return <img src={`${src}/m/`} alt={alt} title={title} />
                                }
                            },
                            markResolvers: {
                                [MARK_LINK]: (children, props) => {
                                    // Always render links in blog articles so they open in a new tab
                                    const { href } = props;
                                    return <a href={href} target="_blank" rel="noreferrer">{children}</a>;
                                }
                            }
                        })
                    }
                </div>
                <StoryblokComponent blok={blok.author[0]} />
            </div>
        </div>
    )
}

export default BlogPost;