import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as AngleDown } from '../assets/svgs/angleDown.svg'
import { ReactComponent as AngleUp } from '../assets/svgs/angleUp.svg'
import { ReactComponent as XMark } from '../assets/svgs/xMark.svg'
import { motion } from "framer-motion"
import ScreenDetect from "../hooks/ScreenDetect";
import { HashLink as Link } from 'react-router-hash-link';
import RequestDemoButton from "./requestDemoButton";
function TabsPage() {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [listTabs, setTabs] = useState([]);
  useEffect(() => {
    fetch('./datasets/tabs.json')
      .then(response => response.json())
      .then(data => setTabs(data.tabs));
  }, []);
  const handleTabClick = (index) => {
    setActiveTab(index);
    navigate(`/${listTabs[index].page}`);
  };
  const location = useLocation();
  const currentTab = listTabs.find(tab => `/${tab.page}` === location.pathname);
  useEffect(() => {
    if (currentTab) {
      setActiveTab(listTabs.indexOf(currentTab));
    }
  }, [currentTab, listTabs]);
  function isBelowLaptopWidth() {
    return window.innerWidth < 1280;
  }
  //checking device width for navigation switch
  const [isBelowLaptop, setisBelowLaptop] = useState(isBelowLaptopWidth());
  useEffect(() => {
    function handleWindowResize() {
      setisBelowLaptop(isBelowLaptopWidth());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const { isMobile } = ScreenDetect();
  return (
    <>
      <div className="container pure-g tabs">
        <div className="pure-u-1 home-navigator"><Link to="/#homepage-tab-navigator">Back to the story <XMark /></Link></div>
        {listTabs.map((tab, index) => (
          <div
            key={index}
            className={`pure-u-1 tab-item${index === activeTab ? " active" : ""}`}
          >
            <div className={`pure-g tab-activator link`} onClick={() => handleTabClick(index)}>
              <span className={`${isBelowLaptop ? 'pure-u-2-24' : 'pure-u-1-24'}`}>{index + 1}</span>
              <a className={`${isBelowLaptop ? 'pure-u-21-24' : 'pure-u-22-24'}`} onClick={() => handleTabClick(index)}>
                {tab.name}
              </a>
              <span className={`${isBelowLaptop ? 'pure-u-1-24' : 'pure-u-1-24'} pinch-out-icon link`} onClick={() => handleTabClick(index)}>
                <AngleDown />
                <AngleUp />
              </span>
            </div>
            {tab.page === currentTab?.page && (
              <>
              <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }} className={`tab-content ${isBelowLaptop ? 'pure-g' : 'pure-u-23-24'} tabs-container`}>
                <div className='tabs-child-content'>
                  <div className='pure-u-lg-12-24 pure-u-md-18-24 text tabs-child-content-text' dangerouslySetInnerHTML={{ __html: tab.content }}>
                  </div>
                  {/* <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: -125 }} transition={{ duration: 0.5}} className={`tab-content-left ${isBelowLaptop ? 'pure-g' : 'pure-u-23-24'}`}>
                    <RequestDemoButton parentPage={tab.name} />
                  </motion.div> */}
                </div>
                <div className={`${isMobile ? 'pure-u-24-24' : 'pure-u-lg-14-24 pure-u-sm-24-24'} svg tab-content-image`} dangerouslySetInnerHTML={(isMobile) ? { __html: tab.image.mobile } : { __html: tab.image.desktop }}>
                </div> 
                
              </motion.div>
              <motion.div initial={{ opacity: 0, y: 15 }} animate={{ opacity: 1, y: -275 }} transition={{ duration: 1}} className={`tab-content-left ${isBelowLaptop ? 'pure-g' : 'pure-u-23-24'}`}>
                <RequestDemoButton parentPage={tab.name} />
              </motion.div>
              </>
            )}
          </div>
        ))}
        <div className="pure-u-1 home-navigator"><Link end to="/#homepage-tab-navigator" smooth>Back to the story <XMark /></Link></div>
      </div>
    </>
  )
}
export default TabsPage