import React, { useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as ArrowRight } from '../assets/svgs/arrowRight.svg'
import { motion } from "framer-motion"
export default function ThankYou() {
    const location = useLocation();
    const navigate = useNavigate();
    const { additionalProp } = location.state || {};

    // console.log("Props from contact page in Thank you page: ", additionalProp);
    useEffect(() => {
        if (location.state?.from !== 'contact-page') {
            navigate('/page-not-found');
          }
    }, []);
    return (
            <motion.div className='container thank-you-page' initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
                <div className='pure-g pure-u-xl-6-24 page-title '>
                    {additionalProp?.value === 'Request a demo' ? 
                    <h1 style={{lineHeight:1.10}}>You are on the waitlist!</h1>
                     : <h1>Thank you</h1>}
                </div>
                <div className='pure-u-xl-6-24 pure-u-md-12-24 page-title thank-you-message-container'>
                    {additionalProp?.value === 'Request a demo' ? <p>Your demo request has been sent to the team. We will do our best to get back to you but are currently receiving high counts of communication, so we have added you to our waitlist. Please bear with us as we deal with your interest.</p> : <p>Your message has been sent to the team. We will do our best to get back to you but are currently receiving high amounts of communication. Please bear with us as we deal with your interest.</p>}
                </div>
                <div className='pure-u-1 button-container'>
                    <div className='pure-u-xl-4-24 pure-u-md-8-24'>
                        <fieldset className='buttonNavigator'>
                            <NavLink className="" end to="/">Back to Homepage</NavLink>
                            <ArrowRight />
                        </fieldset>
                    </div>
                </div>
            </motion.div>
    )
}
