import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as IconEmail } from "../assets/svgs/email.svg";
import { ReactComponent as IconLinkedin } from "../assets/svgs/linkedin.svg";
import { useLocation } from "react-router-dom";
import PageLinks from "./PageLinks";
export default function Footer(props) {
  const location = useLocation();
  const isYear = new Date().getFullYear();
  const isPreLoaderLoaded = props.isPreLoaderLoaded;
  return (
    (isPreLoaderLoaded || location.pathname !== "/") && (
      <>
        <div className="container footer">
          <div className="pure-g top-level">
            <div className="pure-u-12-24 quick-links">
              <PageLinks isFooter="yes" />
            </div>
            <div className="pure-u-12-24 extra-links">
              <div className="information-links">
                <ul>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "active-link" : "link"
                      }
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="social-links">
                <ul>
                  <li>
                    <NavLink to="/contact">
                      <IconEmail />
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/clearly-earth/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconLinkedin />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="pure-g lowel-level">
            <div className="pure-u copyright">
              <p>
                © Clearly {isYear}, Together Global Ltd. trading as "Clearly"
                <span className="version">
                  v{process.env.REACT_APP_VERSION}
                </span>
              </p>
              <p className="footer-text">
                EIT Urban Mobility is supported by the European Institute of
                Innovation and Technology (EIT), a body of the European Union
              </p>
            </div>
            <div className="pure-u website-by">
              <p>
                Website: <a href="https://togethercreative.co.uk/">Together</a>
              </p>
            </div>
          </div>
        </div>
      </>
    )
  );
}
