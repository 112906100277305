// Components
import FreeTextField from '../../../components/Inputs/FreeTextField';
import GreyHelperText from '../../../components/Typography/GreyHelperText';
import WhiteFieldLabel from '../../../components/Typography/WhiteFieldLabel';
import WhiteSectionHeading from '../../../components/Typography/WhiteSectionHeading';
import CalculatorActionButton from '../../../components/Buttons/CalculatorActionButton';

// Hooks
import ScreenDetect from '../../../hooks/ScreenDetect';
import useLeadCapture from '../../../hooks/leads/useLeadCapture';

const ContactDetails = () => {
  //

  // Hooks
  const { isMobile } = ScreenDetect();
  const {
    data: {
      // Inputs
      name,
      emailAddress,
      companyName,
      jobTitle,
      fleetSize,

      // Hygiene
      hasAcceptedTerms,
      formErrorMessage,

      // Submitted
      hasSubmittedForm,
      isSavingLeadContactDetails,
    },
    operations: {
      // Helpers
      checkFormValid,
      checkEmailValid,

      // Handlers
      onUpdateContactForm,
      onSubmitContactDetailsForm,
    },
  } = useLeadCapture();

  const isFormValid = checkFormValid();
  const isEmailValid = checkEmailValid(emailAddress);

  const showEmailInvalidMessage = !isEmailValid && emailAddress.length > 0;
  const showFormInvalidMessage =
    !isFormValid && !hasSubmittedForm && !!formErrorMessage;

  return (
    <>
      <div
        style={{
          width: isMobile ? 'calc(100%)' : '100%',
          background: '#E4ACAC33',
          padding: '20px',
          borderRadius: '10px',
          marginTop: isMobile ? '40px' : '-20px',
          marginLeft: isMobile ? '0px' : '-20px',
          minHeight: isMobile ? 'auto' : '100px',
          transition: 'height 0.6s ease-out',
        }}
      >
        {hasSubmittedForm ? (
          <>
            <WhiteSectionHeading>Thank you!</WhiteSectionHeading>
            <GreyHelperText>
              Thank you for submitting your contact details, Clearly will be in
              touch shortly :)
            </GreyHelperText>
          </>
        ) : (
          <>
            <WhiteSectionHeading>Find out more</WhiteSectionHeading>

            <div style={{ marginTop: '15px' }}>
              <WhiteFieldLabel labelText="Name" required isValid={!!name} />

              <FreeTextField
                placeholder="Your name"
                currentValue={name}
                type="text"
                onChange={(value) => onUpdateContactForm('name', value)}
              />
            </div>

            <div style={{ marginTop: '15px' }}>
              <WhiteFieldLabel
                labelText="Email"
                required
                isValid={!!checkEmailValid(emailAddress)}
              />

              <FreeTextField
                placeholder="Your email"
                currentValue={emailAddress}
                type="text"
                onChange={(value) => onUpdateContactForm('emailAddress', value)}
              />

              {showEmailInvalidMessage && (
                <GreyHelperText>
                  <span
                    style={{
                      fontSize: '16px',
                      marginRight: '4px',
                      color: '#FA7273',
                    }}
                  >
                    *
                  </span>
                  Please enter a valid email address
                </GreyHelperText>
              )}
            </div>

            <div style={{ marginTop: '15px' }}>
              <WhiteFieldLabel
                labelText="Company name"
                required
                isValid={!!companyName}
              />

              <FreeTextField
                placeholder="Your company name"
                currentValue={companyName}
                type="text"
                onChange={(value) => onUpdateContactForm('companyName', value)}
              />
            </div>

            <div style={{ marginTop: '15px' }}>
              <WhiteFieldLabel
                labelText="Job title"
                required
                isValid={!!jobTitle}
              />

              <FreeTextField
                placeholder="Your job title"
                currentValue={jobTitle}
                type="text"
                onChange={(value) => onUpdateContactForm('jobTitle', value)}
              />
            </div>

            <div style={{ marginTop: '15px' }}>
              <WhiteFieldLabel
                labelText="Fleet size and details"
                required
                isValid={!!fleetSize}
              />

              <FreeTextField
                placeholder="Number of vehicles in your fleet"
                currentValue={fleetSize}
                type="text"
                onChange={(value) => onUpdateContactForm('fleetSize', value)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginTop: '15px',
              }}
            >
              <input
                type="checkbox"
                checked={hasAcceptedTerms}
                onChange={() =>
                  onUpdateContactForm('hasAcceptedTerms', !hasAcceptedTerms)
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    onUpdateContactForm('hasAcceptedTerms', !hasAcceptedTerms);
                  }
                }}
                style={{ marginRight: '10px' }}
              />
              <WhiteFieldLabel
                labelText="I agree to receive communications from Clearly"
                required
                isValid={hasAcceptedTerms}
              />
            </div>
          </>
        )}
      </div>

      {!hasSubmittedForm && (
        <>
          {showFormInvalidMessage && (
            <div
              style={{ marginBottom: '16px', width: '100%', marginTop: '20px' }}
            >
              <GreyHelperText>
                <span
                  style={{
                    fontSize: '16px',
                    marginRight: '4px',
                    color: '#FA7273',
                  }}
                >
                  *
                </span>
                {formErrorMessage}
              </GreyHelperText>
            </div>
          )}
          <div
            style={{
              width: isMobile ? '100%' : 'calc(100% - 20px)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginTop: '40px',
            }}
          >
            <CalculatorActionButton
              buttonText={
                isSavingLeadContactDetails ? 'Submitting...' : 'Submit'
              }
              disabled={isSavingLeadContactDetails}
              onClick={onSubmitContactDetailsForm}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ContactDetails;
