import axios from "axios";

const BASEURL = process.env.REACT_APP_API_URL; // 'http://localhost:3501/';

export const postContactFormDetails = async (payload) => {
    try {
        const response = await axios.post(`${BASEURL}requestDemo`, payload);
        // Assuming your API returns some data upon successful POST, you can handle it here
        // console.log("Submit form response: ", response.data);
        return response.data; // Optionally, return any data you receive from the server
    } catch (error) {
        // Handle errors
        console.error('Error posting contact form details:', error);
        throw error; // Propagate the error back to the caller
    }
}
 