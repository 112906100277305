import React, { useEffect, useState } from 'react'
import { useLottie } from "lottie-react";
import preLoader from "../assets/json/preloader.json";
function Preloader() {
  const options = {
    animationData: preLoader,
    loop: true
  };
  const { View } = useLottie(options);
  const [isFadeOut, setFadeOut] = useState(false)
  useEffect(() => {
    const isLoaded = setTimeout(() => {
      setFadeOut(true);
    }, 4500);
    return () => clearTimeout(isLoaded);
  }, []);
  return (
    <>
      <div className={`pre-loader-container ${isFadeOut && 'loaded'}`} >
        {View}
      </div>
    </>
  )
}
export default Preloader