interface WhiteFieldLabelProps {
  labelText: string;
  required?: boolean;
  isValid?: boolean;
}

const WhiteFieldLabel = ({
  labelText,
  required,
  isValid,
}: WhiteFieldLabelProps) => {
  // Variables
  const showIsRequired = required && !isValid;
  return (
    <p style={{ fontSize: '16px', marginBottom: '4px', color: 'white' }}>
      {labelText}
      {showIsRequired && (
        <span
          style={{
            marginLeft: '4px',
            color: '#FA7273',
          }}
        >
          *
        </span>
      )}
    </p>
  );
};

export default WhiteFieldLabel;
