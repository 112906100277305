import React, { useEffect, useState } from 'react'
import ScreenDetect from '../../hooks/ScreenDetect';
import { AnimatePresence, motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { ReactComponent as TabArrowBig } from "../../assets/svgs/tabArrowBig.svg"
import { ReactComponent as ArrowRight } from '../../assets/svgs/arrowRight.svg';
import PathRight from './PathRight';
import { assets } from '../../assets/aws-assets';
function TabNavigator() {
    const navigate = useNavigate();
    const [listTabs, setTabs] = useState([]);
    const [activeTab, setIsActiveTab] = useState('');
    useEffect(() => {
        fetch('./datasets/tabs.json')
            .then(response => response.json())
            .then(data => setTabs(data.tabs));
    }, []);
    const { isMobile, isTab, isLaptop } = ScreenDetect();
    return (
        <>
            <div className='container container-home homepage-tab-navigator pure-g' id="homepage-tab-navigator">
                <motion.div className={`left ${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-12-24' : ''}${isLaptop ? ' pure-u-11-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-10-24" : ''}`} initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.7 }}>
                    <div className='title-row pure-g'>
                        <h3>Clearly lets you:</h3>
                    </div>
                    <a className='list-tabs link pure-' onMouseLeave={() => { setIsActiveTab("") }}>
                        {listTabs.map((tab, index) => (
                                <div key={index} className={`tab-single link tab-${index} pure-g`} onClick={() => navigate(tab.page)} onMouseEnter={() => { setIsActiveTab(index) }} >
                                    <div className='pure-u-1-24 left'>
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className='pure-u-23-24 right'>
                                        <h1>{tab.name}</h1>
                                        <div className='excerpt-container' dangerouslySetInnerHTML={{ __html: tab.excerpt }}></div>
                                    </div>
                                    {(!isTab && !isMobile) &&
                                        <AnimatePresence mode="wait">
                                            {activeTab === index &&
                                                <>
                                                    <motion.div key={tab.name} className='active-tab-handle' initial={{ background: "#FF7C7C40", opacity: 0, y: 250, height: 0 }} animate={{ opacity: 1, y: 0, height: 500 }} exit={{ y: 250, height: 0, delay: 0.2 }} transition={{ duration: 0.3 }}></motion.div>
                                                    <motion.div className='active-arrow-svg' initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100, delay: 0.2 }} transition={{ duration: 0.3 }} ><TabArrowBig /></motion.div>
                                                </>
                                            }
                                        </AnimatePresence>
                                    }
                                    {(isTab || isMobile) &&
                                        <div className='pure-u-1 buttonNavigator'>
                                            <button type="button" onClick={() => navigate(tab.page)}>
                                                Find out more
                                            </button>
                                            <ArrowRight />
                                        </div>
                                    }
                                </div>
                        ))}
                    </a>
                </motion.div>
                <div className={`${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-11-24' : ''}${isLaptop ? ' pure-u-8-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-6-24" : ''} paths right`}>
                    {!isMobile ?
                        <PathRight />
                        :
                        <motion.div initial={{ opacity: 0, y: -80 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} className='gif-container'>
                            <img src={assets.mobileCarGIF} className='gif' alt="car-moving-animation" loading='lazy'></img>
                        </motion.div>
                    }
                </div>
            </div>
        </>
    )
}
export default TabNavigator