//

// Defaults
export const CLASS_8_NUMBER_TYRES = 18;
export const ANNUAL_COST_AVERAGE = 5;

export const INITIATIVES = [
  'oil',
  'wheel',
  'tyre_type',
  'tyre_pressure',
  'aerodynamics',
];

//

// Prices
export const fuel_efficiency = {
  class_8: {
    diesel: 0.35,
    b20: 0.355,
  },
}; // L/km

export const fuel_price = {
  usa: {
    diesel: 0.96,
    b20: 0.94,
  },
  eu: {
    diesel: 1.82,
    b20: 1.82,
  },
  uk: {
    diesel: 1.8,
    b20: 1.8,
  },
}; // $

export const fuel_emissions = {
  diesel: 3.43,
  b20: 2.95,
}; // L to kg

export const emissions_price = {
  usa: 0.035,
  eu: 0.064,
  uk: 0.064,
}; // $/kg

//

// Reduction options
export const fuel_reduction_options = {
  class_8: {
    oil: {
      conventional_15W40: 1.0,
      synthetic_5W30: 0.97,
    },
    // Percetage of fuel due to Oil
    wheel: {
      steel: 1.0,
      aluminium: 0.985,
    },
    // Percentage of fuel due to wheel weight
    tyre_type: {
      G: 1.0,
      A: 0.96,
    },
    // Percentage of fuel due to tyre types (being conservative as it could be 9%)
    tyre_pressure: {
      bad: 1.0,
      good: 0.96,
    },
    // Percentage of fuel due to tyre pressure (being ambitious but most citations give 2 years ROI)
    // NOTE: When both tyres options are combined they should be around the entire rolling resistance which is around 8%
    // fuel efficiency improvement when rolling resistance is reduced by around 30%
    aerodynamics: {
      bad: 1.0,
      good: 0.93,
    },
    // Percentage to fuel (assuming fully loaded truck), this could be more if we need to push things
  },
};

export const initiative_prices = {
  class_8: {
    oil: {
      conventional_15W40: 135,
      synthetic_5W30: 270,
    },
    // Percetage of fuel due to Oil
    wheel: {
      steel: (50 * CLASS_8_NUMBER_TYRES) / ANNUAL_COST_AVERAGE,
      aluminium: (150 * CLASS_8_NUMBER_TYRES) / ANNUAL_COST_AVERAGE,
    },
    // Percentage of fuel due to wheeel weight
    tyre_type: {
      G: 300 * CLASS_8_NUMBER_TYRES,
      A: 400 * CLASS_8_NUMBER_TYRES,
    },
    // Percentage of fuel due to tyre types (being conservative as it could be 9%)
    tyre_pressure: {
      bad: 0,
      good: ((CLASS_8_NUMBER_TYRES / 4) * 800) / ANNUAL_COST_AVERAGE, // USD and per 4 tyres axel
    },
    // Percentage of fuel due to tyre pressure (being ambitious but most citations give 2 years ROI)
    // NOTE: When both tyres options are combined they should be around the entire rolling resistance which is around 8%
    // fuel efficiency improvement when rolling resistance is reduced by around 30%
    aerodynamics: {
      bad: 0,
      good: 2200 / ANNUAL_COST_AVERAGE,
    },
    // Percentage to fuel (assuming fully loaded truck), this could be more if we need to push things
  },
};

export const initiative_distance = {
  class_8: {
    oil: {
      conventional_15W40: 30000,
      synthetic_5W30: 60000,
    },
    // Percetage of fuel due to Oil
    wheel: {
      steel: 0,
      aluminium: 0,
    },
    // This is not totally true but lets assume it does not apply so its None
    tyre_type: {
      G: 150000,
      A: 150000 * 1.1,
    },
    // Lets assume that A tyres last 10% more than G
    tyre_pressure: {
      bad: 0,
      good: 0,
    },
    // This is not totally true but lets assume it does not apply so its None
    aerodynamics: {
      bad: 0,
      good: 0,
    },
    // This is not totally true but lets assume it does not apply so its None
  },
};
