import { useEffect } from 'react';

// Hooks
import ScreenDetect from '../../../hooks/ScreenDetect';
import useReductionPotential from '../../../hooks/calculators/useReductionPotential';

// Components
import ContactDetailsForm from './ContactDetailsForm';
import GreyHelperText from '../../../components/Typography/GreyHelperText';
import WhiteFieldLabel from '../../../components/Typography/WhiteFieldLabel';
import GreenHeroValue from '../../../components/Typography/GreenHeroValue';
import WhiteSectionHeading from '../../../components/Typography/WhiteSectionHeading';

// Icons
import { ChevronLeft } from '@mui/icons-material';

// Analytics
import { logEvent } from '../../../analytics';

const VehicleInputs = () => {
  //

  // Hooks
  const {
    data: {
      // Inputs
      currency,

      // Outputs
      costReduction = 0,
      emissionsReduction = 0,
    },
    operations: { onGoToPreviousStep },
  } = useReductionPotential();
  const { isMobile } = ScreenDetect();

  //

  // Variables
  let selectedCurrencySymbol = '';

  switch (currency.value) {
    case 'usa':
      selectedCurrencySymbol = '$';
      break;
    case 'eu':
      selectedCurrencySymbol = '€';
      break;
    case 'uk':
      selectedCurrencySymbol = '£';
      break;
    default:
      selectedCurrencySymbol = '$';
  }

  //

  // Effects

  useEffect(() => {
    if (costReduction !== 0) {
      logEvent(
        'Calculator Estimation',
        'Output: Cost Reduction',
        'Cost Reduction',
        costReduction
      );
    }
    if (emissionsReduction !== 0) {
      logEvent(
        'Calculator Estimation',
        'Output: Emission Reduction',
        'Emission Reduction',
        emissionsReduction
      );
    }
  }, [costReduction, emissionsReduction]);

  return (
    <>
      {/* Reductions Section */}
      <div
        id="reductions-output"
        style={{
          flex: '1 1 300px',
          minWidth: '300px',
          maxWidth: '100%',
        }}
      >
        <WhiteSectionHeading>Our estimation</WhiteSectionHeading>

        <div style={{ marginTop: '15px' }}>
          <GreyHelperText>
            We have modelled upgrading existing ICE vehicles with more efficient
            parts, without need for transitioning to EVs.
          </GreyHelperText>

          <div style={{ marginTop: '32px' }}>
            <WhiteFieldLabel labelText="Your Cost Reduction Potential" />

            <GreyHelperText>
              This is an annual cost saving also accounting for the upfront cost
              of reduction initiatives and the cost of carbon.
            </GreyHelperText>
            <GreenHeroValue
              primaryValue={costReduction}
              prefix={selectedCurrencySymbol}
            />
          </div>

          <div style={{ marginTop: '32px' }}>
            <WhiteFieldLabel labelText="Your Emission Reduction Potential" />
            <GreyHelperText>
              We calculate emissions following GLEC and ISO14083 Standards.
            </GreyHelperText>
            <GreenHeroValue
              primaryValue={emissionsReduction}
              suffix={`tCO₂e`}
            />
          </div>

          <button
            id="rpc-recalculate-button"
            style={{
              width: '150px',
              border: `1px solid white`,
              color: 'white',
              padding: '10px',
              borderRadius: '4px',
              marginTop: isMobile ? '5vh' : '15vh',
            }}
            onClick={onGoToPreviousStep}
          >
            <ChevronLeft style={{ color: 'inherit' }} />
            Recalculate
          </button>
        </div>
      </div>

      {/* Fleet Section */}
      <div
        style={{
          flex: '1 1 300px',
          minWidth: '300px',
          maxWidth: '100%',
        }}
      >
        <ContactDetailsForm />
      </div>
    </>
  );
};

export default VehicleInputs;
