export const INPUT_VEHICLES = [
  {
    // Currenlty dynamic
    vehicle_number: 50,
    vehicle_type: 'class_8',
    fuel_type: 'diesel',
    distance: 100000,
    region: 'usa',
    // Currently defaults
    oil_type: 'conventional_15W40',
    wheel_type: 'steel',
    tyre_type: 'G',
    tyre_pressure: 'bad',
    aerodynamics: 'bad',
  },
];
