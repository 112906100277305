import { motion } from 'framer-motion';

// Components
import PathRight from './PathRight';
import SimpleRequestDemoButton from '../Buttons/SimpleRequestDemoButton';

// Hooks
import ScreenDetect from '../../hooks/ScreenDetect';

// Assets
import { assets } from '../../assets/aws-assets';

function HereSection() {
  const { isMobile, isTab, isLaptop, is4k } = ScreenDetect();
  return (
    <>
      <div className="container container-home homepage-hero-section pure-g">
        <div
          className={`${isMobile ? ' pure-u-24-24' : ''}${
            isTab ? ' pure-u-12-24' : ''
          }${isLaptop ? ' pure-u-16-24' : ''}${
            !isMobile && !isTab && !isLaptop ? ' pure-u-14-24' : ''
          }  left`}
        >
          <motion.div
            className="highlight pure-g"
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 80 }}
            transition={{ duration: 0.8 }}
          >
            <h2
              className={`${isMobile ? ' pure-u-24-24' : ''}${
                isTab ? ' pure-u-24-24' : ''
              }${isLaptop ? ' pure-u-19-24' : ''}${
                !isMobile && !isTab && !isLaptop && !is4k ? ' pure-u-20-24' : ''
              }${is4k ? ' pure-u-16-24' : ''}`}
            >
              <span>Driving global transportation to net-zero</span>
              <br />
              Turn your emission targets into action
            </h2>
          </motion.div>

          {isMobile ? (
            <motion.div
              initial={{ opacity: 0, y: -80 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="gif-container"
            >
              <img
                src={assets.mobileCarGIF}
                className="gif"
                alt="car-moving-aniamtion"
                loading="lazy"
              />
            </motion.div>
          ) : (
            <div
              style={{
                marginTop: '10vh',
                marginBottom: '5vh',
              }}
            >
              <SimpleRequestDemoButton parentPage="Landing page" />
            </div>
          )}
        </div>
        <motion.div
          initial={{ opacity: 0, y: -80 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className={`${isMobile ? ' pure-u-24-24' : ''}${
            isTab ? ' pure-u-11-24' : ''
          }${isLaptop ? ' pure-u-8-24' : ''}${
            !isMobile && !isTab && !isLaptop ? ' pure-u-6-24' : ''
          } paths right`}
        >
          {!isMobile && <PathRight />}
        </motion.div>
        {isMobile && (
          <div
            style={{
              padding: '0 15px',
              marginTop: '5vh',
              width: '100%',
            }}
          >
            <SimpleRequestDemoButton parentPage="Landing page" />
          </div>
        )}
      </div>
    </>
  );
}
export default HereSection;
