import AnimatedDiv from "../components/AnimatedDiv";
import { motion } from "framer-motion"
import { useStoryblok, StoryblokComponent } from "@storyblok/react";
import { useParams } from "react-router-dom";

const BlogPost = () => {

    const { slug } = useParams();
    const story = useStoryblok(`blog/${slug}`, { version: process.env.REACT_APP_SHOW_DRAFT_CMS_CONTENT === "true" ? "draft" : "published" });

    return (
        <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <AnimatedDiv>
                <div className="pure-g container tw-py-8 tw-justify-center">
                    {
                        story && story.content && <StoryblokComponent blok={story.content} created_at={story.created_at} published_at={story.published_at} />
                    }
                </div>
            </AnimatedDiv>
        </motion.div>
    )
}

export default BlogPost;