interface GreenHeroPercentageProps {
  primaryValue: number;
  prefix?: string;
  suffix?: string;
}

const GreenHeroValue = ({
  primaryValue,
  prefix,
  suffix,
}: GreenHeroPercentageProps) => {
  //

  // Variables

  const valueToDisplay = primaryValue > 0 ? primaryValue : 0;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        marginTop: '15px',
      }}
    >
      {prefix && (
        <h3
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#40E5AA',
            marginLeft: '4px',
          }}
        >
          {prefix}
        </h3>
      )}
      <h1
        style={{
          fontSize: '60px',
          lineHeight: '50px',
          fontWeight: 'bold',
          color: '#40E5AA',
          margin: '0px',
        }}
      >
        {valueToDisplay.toLocaleString()}
      </h1>
      {suffix && (
        <h3
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#40E5AA',
            marginLeft: '4px',
          }}
        >
          {suffix}
        </h3>
      )}
    </div>
  );
};

export default GreenHeroValue;
