import { useEffect, useState } from 'react'
function isMobileWidth() {
    return window.innerWidth < 768;
}
function isTabWidth() {
    return window.innerWidth < 1190 && window.innerWidth > 767;
}
function isLaptopWidth() {
    return window.innerWidth < 1590 && window.innerWidth > 1190;
}
function is4kWidth() {
    return window.innerWidth > 2289;
}
function ScreenDetect() {
    const [isMobile, setIsMobile] = useState(isMobileWidth());
    const [isTab, setIsTab] = useState(isTabWidth());
    const [isLaptop, setIsLaptop] = useState(isLaptopWidth());
    const [is4k, setIs4k] = useState(is4kWidth());
    useEffect(() => {
        function handleWindowResize() {
            setIsMobile(isMobileWidth());
            setIsTab(isTabWidth());
            setIsLaptop(isLaptopWidth());
            setIs4k(is4kWidth());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    return {
        isMobile,
        isTab,
        isLaptop,
        is4k
    }
}
export default ScreenDetect