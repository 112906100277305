import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer';
export default function AnimatedDiv({ children }) {
    const { ref, inView } = useInView({
      rootMargin: '0px 0px -20% 0px',
      threshold: 0,
    });
    return (
      <motion.div
        ref={ref}
        animate={inView ? 'animateIn' : 'animateOut'}
        variants={{
          animateIn: { opacity: 1, y: 0 },
          animateOut: { opacity: 0, y: -15 },
        }}
        transition={{ duration: 0.8 }}
      >
        {children}
      </motion.div>
    );
  }