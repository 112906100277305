// Components
import FreeTextField from '../../../components/Inputs/FreeTextField';
import DropDownOption from '../../../components/Inputs/DropDownOption';
import GreyHelperText from '../../../components/Typography/GreyHelperText';
import WhiteFieldLabel from '../../../components/Typography/WhiteFieldLabel';
import WhiteSectionHeading from '../../../components/Typography/WhiteSectionHeading';
import CalculatorActionButton from '../../../components/Buttons/CalculatorActionButton';

// Hooks
import ScreenDetect from '../../../hooks/ScreenDetect';
import useReductionPotential from '../../../hooks/calculators/useReductionPotential';

// Types
import { LabelValuePair } from '../../../context/calculators/reductionsCalculator';

// Analytics
import { logEvent } from '../../../analytics';

const VehicleInputs = () => {
  //

  // Hooks
  const {
    data: {
      region,
      unit,
      currency,
      vehicleType,
      fuelType,
      numberOfVehicles,
      annualDistancePerVehicle,
      regionOptions = [],
      unitOptions = [],
      currencyOptions = [],
      vehicleTypeOptions = [],
      fuelTypeOptions = [],

      // Calulator meta
      isCalculatingReductionPotential,
    },
    operations: { updateInputValue, onCalculateReductionPotential },
  } = useReductionPotential();
  const { isMobile } = ScreenDetect();

  //

  // Functions
  const isFormValid = !!numberOfVehicles && !!annualDistancePerVehicle;

  //

  // Handlers

  const handleSelectInputValue = (
    inputField: string,
    inputValue: LabelValuePair | number
  ) => {
    logEvent('User Input', `${inputField} Input Changed`, inputValue);
    updateInputValue(inputField, inputValue);
  };

  const handleRegionChange = (inputValue: LabelValuePair) => {
    logEvent('User Input', 'Region Input Changed', inputValue);
    updateInputValue('region', inputValue);

    // Update linked currency and unit
    const currencyOption = currencyOptions.find(
      (option) => option.value === inputValue.value
    );

    if (currencyOption) {
      updateInputValue('currency', currencyOption);
    }

    // If selecting US or UK, choose miles, otherwise choose km
    const unitOption = ['uk', 'usa'].includes(inputValue.value)
      ? unitOptions[0]
      : unitOptions[1];

    if (unitOption) {
      updateInputValue('unit', unitOption);
    }
  };

  return (
    <>
      {/* Region Section */}
      <div
        style={{
          flex: '1 1 300px',
          minWidth: '300px',
          maxWidth: '100%',
        }}
      >
        <WhiteSectionHeading>Region</WhiteSectionHeading>

        <DropDownOption
          title={'Select your region'}
          required
          selectedOption={region}
          options={regionOptions}
          onSelect={(inputValue) => handleRegionChange(inputValue)}
        />

        <div style={{ marginTop: '15px' }}>
          <div style={{ marginBottom: '8px' }}>
            <GreyHelperText>
              Based on your region, your calculation will be using
            </GreyHelperText>
          </div>

          <DropDownOption
            title={'Unit of measurement'}
            selectedOption={unit}
            options={unitOptions}
            onSelect={(inputValue) =>
              handleSelectInputValue('unit', inputValue)
            }
          />

          <DropDownOption
            title={'Currency'}
            selectedOption={currency}
            options={currencyOptions}
            disabled
            useDarkBorders
          />
        </div>
      </div>

      {/* Fleet Section */}
      <div
        style={{
          flex: '1 1 300px',
          minWidth: '300px',
          maxWidth: '100%',
        }}
      >
        <WhiteSectionHeading>Fleet</WhiteSectionHeading>

        <DropDownOption
          title={'Vehicle Type'}
          selectedOption={vehicleType}
          options={vehicleTypeOptions}
          onSelect={(inputValue) =>
            handleSelectInputValue('vehicleType', inputValue)
          }
        />

        <DropDownOption
          title={'Fuel Type'}
          selectedOption={fuelType}
          options={fuelTypeOptions}
          onSelect={(inputValue) =>
            handleSelectInputValue('fuelType', inputValue)
          }
        />

        <div style={{ marginTop: '15px' }}>
          <WhiteFieldLabel
            labelText="Number of vehicles"
            required
            isValid={!!numberOfVehicles}
          />

          <FreeTextField
            minValue={1}
            maxValue={10000}
            type="number"
            placeholder="8,000"
            currentValue={numberOfVehicles}
            onChange={(value: string) =>
              handleSelectInputValue('numberOfVehicles', parseInt(value))
            }
          />
          <GreyHelperText>Enter a number from 1 to 10,000</GreyHelperText>
        </div>

        <div style={{ marginTop: '15px' }}>
          <WhiteFieldLabel
            labelText={`Annual distance driven per vehicle (in ${unit.label})`}
            required
            isValid={!!annualDistancePerVehicle}
          />
          <FreeTextField
            minValue={1}
            maxValue={500000}
            type="number"
            placeholder="100,000 miles"
            currentValue={annualDistancePerVehicle}
            onChange={(value) =>
              handleSelectInputValue(
                'annualDistancePerVehicle',
                parseInt(value)
              )
            }
          />
          <GreyHelperText>Enter a number from 1 to 500,000</GreyHelperText>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: isMobile ? '40px' : '15px',
          }}
        >
          <CalculatorActionButton
            buttonText={
              isCalculatingReductionPotential ? 'Calculating...' : 'Calculate'
            }
            disabled={isCalculatingReductionPotential || !isFormValid}
            onClick={onCalculateReductionPotential}
          />
        </div>
      </div>
    </>
  );
};

export default VehicleInputs;
