const GreyHelperText = ({ children }) => {
  return (
    <p
      style={{
        color: 'gray',
        fontSize: '0.8em',
      }}
    >
      {children}
    </p>
  );
};

export default GreyHelperText;
