const BlogDate = ({ date }) => {

    const formattedDate = (date) => {
        return Intl.DateTimeFormat('en-UK', {
            day: "numeric",
            month: "short",
            year: "numeric"
        }).format(date)
    }

    return (
        <p className="tw-text-white tw-font-extralight tw-whitespace-nowrap">{ formattedDate(date) }</p>
    )
}

export default BlogDate;