const WhiteSectionHeading = ({ children }) => {
  return (
    <h6
      style={{
        lineHeight: '1.2',
        fontWeight: 'bold',
        marginBottom: '15px',
      }}
    >
      {children}
    </h6>
  );
};

export default WhiteSectionHeading;
