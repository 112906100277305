import { useState } from 'react';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Types
import { LabelValuePair } from '../../context/calculators/reductionsCalculator';

interface SelectInputProps {
  options: LabelValuePair[];
  selectedOption: LabelValuePair;
  placeholder?: string;
  useDarkBorders?: boolean;
  disabled?: boolean;
  onSelect: (selectedOption: LabelValuePair) => void;
}

const SelectInput = ({
  options,
  selectedOption,
  placeholder = 'Select an option...',
  useDarkBorders = false,
  disabled = false,
  onSelect,
}: SelectInputProps) => {
  const [isOpen, setIsOpen] = useState(false);

  // Ensure options is always an array
  const safeOptions = Array.isArray(options) ? options : [];

  // Handlers
  const handleSelectOption = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(false);

    const selectedValue = options.find(
      (option) => option.label === e.currentTarget.textContent
    );

    if (selectedValue) {
      onSelect(selectedValue);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        border: `1px solid ${useDarkBorders ? '#3c3c3c' : '#ccc'}`,
        borderRadius: '5px',
        padding: '10px',
        marginTop: '10px',
      }}
      onClick={disabled ? () => {} : () => setIsOpen(!isOpen)}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          fontWeight: useDarkBorders ? 'normal' : 'bold',
        }}
      >
        <span>{selectedOption ? selectedOption.label : placeholder}</span>
        {!disabled && (
          <ExpandMoreIcon
            sx={{
              transition: 'transform 0.5s',
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        )}
      </div>

      {isOpen && (
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          {safeOptions.length === 0 ? (
            <p
              style={{
                color: 'gray',
              }}
            >
              No options available
            </p>
          ) : (
            safeOptions.map((option) => (
              <button
                key={option.value}
                style={{
                  padding: '10px 0px',
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'left',
                  fontWeight: 'normal',
                  borderBottom:
                    option.value === safeOptions[safeOptions.length - 1].value
                      ? 'none'
                      : '1px solid #ccc',
                }}
                onClick={(e) => handleSelectOption(e)}
              >
                {option.label}
              </button>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SelectInput;
