import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logPageView } from '../analytics';

export default function PageTracker({ trackingEnabled }) {
  const location = useLocation();

  useEffect(() => {
    // Log page views on route change if tracking is enabled
    if (trackingEnabled) {
      logPageView();
    }
  }, [location.pathname, trackingEnabled]);

  return null;
}
