const BlogWrappedImage = ({ blok }) => {

    const Image = () => {
        return (
            <div className="tw-overflow-clip">
                <img className="tw-object-cover tw-w-full tw-h-full" src={`${blok.image.filename}/m/`} alt={blok.image.alt} />
                {
                    blok.image.name &&
                    <p className="tw-text-sm tw-italic tw-text-gray-300">{blok.image.name}</p>
                }
            </div>
        )
    }

    return (
        <>
            {
                blok.image_position === 'left' &&
                    <div className={`sm:tw-pb-2 sm:tw-float-left sm:tw-pr-4 sm:tw-w-${blok.width}`}>
                        <Image />
                    </div>
            }
            {
                blok.image_position === 'right' &&
                    <div className={`sm:tw-pb-2 sm:tw-float-right sm:tw-pl-4 sm:tw-w-${blok.width}`}>
                        <Image />
                    </div>
            }
        </>
    )
}

export default BlogWrappedImage;