import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/svgs/logo.svg";
import { motion } from "framer-motion";
import PageLinks from "./PageLinks";
import GetScroll from "../hooks/getScroll";
function isMobileWidth() {
  return window.innerWidth < 1024;
}
export default function Header(props) {
  //disallow body to scroll once the meny is open
  const bodyRef = useRef(document.body);
  //checking device width for navigation switch
  const [isMobile, setIsMobile] = useState(isMobileWidth());
  //handleing the click of  menu opener i.e. hamburger vertical bars
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    function handleWindowResize() {
      setIsMobile(isMobileWidth());
      setMenuOpen(false);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [isMenuOpen]);
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);
  function handleMobileMenu() {
    setMenuOpen(!isMenuOpen);
    bodyRef.current.classList.toggle("no-scroll");
  }
  const headerTopOnHome = GetScroll();
  const [shouldAddClass, setShouldAddClass] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const shouldAdd = headerTopOnHome <= 50;
      if (shouldAdd && !shouldAddClass) {
        setShouldAddClass(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <>
      <motion.nav
        className={`container navigation-bar pure-g`}
        initial={{ opacity: 0, y: -90 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        <div className="brand-identity pure-u-3-24">
          <NavLink
            end
            className={({ isActive }) =>
              `${(isActive ? "active-link" : "link")} brand`
            }
            to="/">
            <Logo />
          </NavLink>
        </div>
        {isMobile ? (
          <div className="page-list mobile pure-u-21-24">
            <div className="menu-opener">
              <a
                onClick={handleMobileMenu}
                className={`${isMenuOpen ? "active" : ""}`}>
                <div className="bars bar-1"></div>
                <div className="bars bar-2"></div>
                <div className="bars bar-3"></div>
              </a>
            </div>
          </div>
        ) : (
          <div className="page-list desktop pure-u-21-24">
            <PageLinks isFooter="no" />
          </div>
        )}
      </motion.nav>
      {isMenuOpen && (
        <div className="mobile-menu-container">
          <PageLinks isFooter="no" isMobile="yes" />
        </div>
      )}
    </>
  );
}
