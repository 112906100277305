import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Assets
import { ReactComponent as ArrowRight } from '../../assets/svgs/arrowRightBlack.svg';

// Hooks
import ScreenDetect from '../../hooks/ScreenDetect';

interface SimpleRequestDemoButtonProps {
  parentPage: string;
}

export default function SimpleRequestDemoButton({
  parentPage,
}: SimpleRequestDemoButtonProps) {
  // Hooks
  const navigate = useNavigate();
  const { isMobile } = ScreenDetect();

  // State
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger animation after component mounts
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  // Handlers

  const handleSubmit = () => {
    navigate('/contact', { state: { additionalProp: parentPage } });
  };

  return (
    <button
      onClick={handleSubmit}
      style={{
        width: isMobile ? '100%' : '350px',
        background: '#40e5aa',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px',
        borderRadius: '2px',
        opacity: isVisible ? 1 : 0,
        transform: `translateY(${isVisible ? 0 : -80}px)`,
        transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
        visibility: isVisible ? 'visible' : 'hidden',
      }}
    >
      <span>Request a demo</span>
      <ArrowRight
        style={{
          fill: 'black',
        }}
      />
    </button>
  );
}
