import React from "react";
import { ReactComponent as IconLinkedin } from "../assets/svgs/linkedin.svg";
function MemberCard(props) {
  const name = props.memberName;
  const position = props.memberPosition;
  const socials = props.memberSocial;
  const image = props.memberImg;
  const location = props.memberLocation;
  return (
    <div className="single-member">
      <div className="image">
        <img src={image} alt="member"></img>
      </div>
      <div className="pure-g details">
        <div className="pure-u-12-24 mains">
          <h4 className="member-name">{name}</h4>
          <p className="position">{position}</p>
          <p className="position">{location}</p>
        </div>
        <div className="pure-u-12-24 socials">
          <a href={socials} target="_blank" rel="noreferrer">
            <IconLinkedin />
          </a>
        </div>
      </div>
    </div>
  );
}
export default MemberCard;
