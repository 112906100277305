import ScreenDetect from '../../hooks/ScreenDetect';

interface CalculatorActionButtonProps {
  buttonText: string;
  disabled: boolean;
  onClick: () => void;
}

const CalculatorActionButton = ({
  buttonText,
  disabled,
  onClick,
}: CalculatorActionButtonProps) => {
  //

  // Hooks
  const { isMobile } = ScreenDetect();

  return (
    <button
      style={{
        background: '#FA7273',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        width: isMobile ? '100%' : '183px',
      }}
      disabled={disabled}
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
};

export default CalculatorActionButton;
