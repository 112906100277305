import React from 'react';
import ReactDOM from 'react-dom/client';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Components
import App from './App';
import flagsmith from 'flagsmith';
import { initGA } from './analytics';
import BlogHome from './components/Storyblok/BlogHome';
import BlogPost from './components/Storyblok/BlogPost';
import VimeoVideo from './components/Storyblok/VimeoVideo';
import BlogAuthor from './components/Storyblok/BlogAuthor';
import YouTubeVideo from './components/Storyblok/YouTubeVideo';
import BlogWrappedImage from './components/Storyblok/BlogWrappedImage';

// CSS
import 'purecss/build/pure.css';

// Providers
import { FlagsmithProvider } from 'flagsmith/react';
import { LeadCaptureProvider } from './context/leads/useLeadCaptureContext';
import { ReductionsCalculatorsProvider } from './context/calculators/reductionsCalculator';

storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_TOKEN,
  use: [apiPlugin],
  components: {
    blog_home: BlogHome,
    blog_post: BlogPost,
    blog_author: BlogAuthor,
    youtube_video: YouTubeVideo,
    vimeo_video: VimeoVideo,
    blog_wrappedimage: BlogWrappedImage,
  },
  apiOptions: {
    region: 'eu',
  },
});

// Initializing Google Analytics
initGA();

// Create a Tanstack Query client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FlagsmithProvider
      options={{
        environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_KEY,
      }}
      flagsmith={flagsmith}
    >
      <QueryClientProvider client={queryClient}>
        <ReductionsCalculatorsProvider>
          <LeadCaptureProvider>
            <App />
          </LeadCaptureProvider>
        </ReductionsCalculatorsProvider>
      </QueryClientProvider>
    </FlagsmithProvider>
  </React.StrictMode>
);
