import React from 'react'
import AnimatedDiv from "../../components/AnimatedDiv"
import PathRight from './PathRight'
import ScreenDetect from '../../hooks/ScreenDetect';
import RequestDemoButton from '../../views/requestDemoButton';
function Highlighter() {
    const { isMobile, isTab, isLaptop } = ScreenDetect();
    return (
        <>
            <div className='container container-home homepage-highlighter pure-g'>
                
                <div className={`left ${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-12-24' : ''}${isLaptop ? ' pure-u-16-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-14-24" : ''}`}>
                
                {!isMobile &&  <div style={{transform:'translateY(-225px)'}} className='highlight-request-demo'>
                    <RequestDemoButton parentPage='Landing page'/>
                </div>}
                    <div className={`details ${isMobile ? ' pure-u-23-24' : ''}${isTab ? ' pure-u-24-24' : ''}${isLaptop ? ' pure-u-9-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-8-24" : ''}`}>
                        <AnimatedDiv>
                            <p>Managing emissions is currently difficult, costly and inaccurate.</p>
                            <p>The reason: vast amounts of scattered, siloed and missing data.</p>
                            <p><span>Until now.</span></p>
                        </AnimatedDiv>
                        <AnimatedDiv>
                            <div className='additional'>
                                <p>Clearly gathers, synthesises and fuses data at the trip level.</p>
                                <p>Let’s take a look.</p>
                            </div>
                        </AnimatedDiv>
                    </div>
                    
                </div>
                <div className={`${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-11-24' : ''}${isLaptop ? ' pure-u-8-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-6-24" : ''} paths right`}>
                    {!isMobile &&
                        <PathRight />
                    }
                </div>
            </div>
        </>
    )
}
export default Highlighter