import React from 'react'
function TollboothDesktop(props) {
    const switchTrafficLight = props.switchTrafficLight;
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 489 550.879" className='toolbooth-roof'>
                <defs>
                    <clipPath id="clip-path-toll">
                        <rect id="Rectangle_12957" data-name="Rectangle 12957" width="489" height="479.065" transform="translate(951 4789)" fill="#fff" stroke="#707070" strokeWidth="1" />
                    </clipPath>
                </defs>
                <g id="Group_25180" data-name="Group 25180" transform="translate(-951 -4531)">
                    <rect id="Rectangle_12942" data-name="Rectangle 12942"transform="translate(951 4531)" fill="#f0f0f0" />
                    <g id="Group_19767" data-name="Group 19767" transform="translate(-9 -216.751)">
                        <rect id="Rectangle_9054" data-name="Rectangle 9054" width="58" height="29" rx="14.5" transform="translate(1097 4770)" />
                        <ellipse id="Ellipse_621" data-name="Ellipse 621" cx="12" cy="11.5" rx="12" ry="11.5" transform="translate(1101 4773)" fill={!switchTrafficLight ? '#c61d85' : 'none' } stroke={switchTrafficLight ? '#707070' : 'none' } />
                        <g id="Ellipse_622" data-name="Ellipse 622" transform="translate(1127 4773)" fill="none" stroke={!switchTrafficLight ? '#707070' : 'none' } strokeWidth="1">
                            <ellipse cx="12" cy="11.5" rx="12" ry="11.5" stroke="none" />
                            <ellipse cx="12" cy="11.5" rx="11.5" ry="11" fill={switchTrafficLight ? '#c61d85' : 'none' } />
                        </g>
                    </g>
                    <g id="Group_19859" data-name="Group 19859" transform="translate(-9 262)">
                        <rect id="Rectangle_9054-2" data-name="Rectangle 9054" width="58" height="29" rx="14.5" transform="translate(1097 4770)" />
                        <ellipse id="Ellipse_621-2" data-name="Ellipse 621" cx="12" cy="11.5" rx="12" ry="11.5" transform="translate(1101 4773)" fill={!switchTrafficLight ? '#c61d85' : 'none' } stroke={switchTrafficLight ? '#707070' : 'none' } />
                        <g id="Ellipse_622-2" data-name="Ellipse 622" transform="translate(1127 4773)" fill="none" stroke={!switchTrafficLight ? '#707070' : 'none' } strokeWidth="1">
                            <ellipse cx="12" cy="11.5" rx="12" ry="11.5" stroke="none" />
                            <ellipse cx="12" cy="11.5" rx="11.5" ry="11" fill={switchTrafficLight ? '#c61d85' : 'none' } />
                        </g>
                    </g>
                    <g id="Group_19766" data-name="Group 19766" transform="translate(15 -216.751)">
                        <g id="Group_19763" data-name="Group 19763" transform="translate(64 -3)">
                            <rect id="Rectangle_9054-3" data-name="Rectangle 9054" width="58" height="29" rx="14.5" transform="translate(1167 4773)" />
                            <g id="Ellipse_621-3" data-name="Ellipse 621" transform="translate(1171 4776)" fill="none" stroke={!switchTrafficLight ? '#707070' : 'none' } strokeWidth="1">
                                <ellipse cx="12" cy="11.5" rx="12" ry="11.5" stroke="none" />
                                <ellipse cx="12" cy="11.5" rx="11.5" ry="11" fill={switchTrafficLight ? '#ff7c7c' : 'none' } />
                            </g>
                            <ellipse id="Ellipse_622-3" data-name="Ellipse 622" cx="12" cy="11.5" rx="12" ry="11.5" transform="translate(1197 4776)" fill={!switchTrafficLight ? '#ff7c7c' : 'none' } stroke={switchTrafficLight ? '#707070' : 'none' } />
                        </g>
                    </g>
                    <g id="Group_19860" data-name="Group 19860" transform="translate(15 262)">
                        <g id="Group_19763-2" data-name="Group 19763" transform="translate(64 -3)">
                            <rect id="Rectangle_9054-4" data-name="Rectangle 9054" width="58" height="29" rx="14.5" transform="translate(1167 4773)" />
                            <g id="Ellipse_621-4" data-name="Ellipse 621" transform="translate(1171 4776)" fill="none" stroke={!switchTrafficLight ? '#707070' : 'none' } strokeWidth="1">
                                <ellipse cx="12" cy="11.5" rx="12" ry="11.5" stroke="none" />
                                <ellipse cx="12" cy="11.5" rx="11.5" ry="11" fill={switchTrafficLight ? '#ff7c7c' : 'none' } />
                            </g>
                            <ellipse id="Ellipse_622-4" data-name="Ellipse 622" cx="12" cy="11.5" rx="12" ry="11.5" transform="translate(1197 4776)"  fill={!switchTrafficLight ? '#ff7c7c' : 'none' } stroke={switchTrafficLight ? '#707070' : 'none' } />
                        </g>
                    </g>
                    <g id="Mask_Group_12761" data-name="Mask Group 12761" transform="translate(0 -186.187)" clipPath="url(#clip-path-toll)">
                        <g id="Group_19770" data-name="Group 19770" transform="translate(1033 4800.08)">
                            <g id="Rectangle_9046" data-name="Rectangle 9046" transform="translate(0 0)" fill="none" stroke="#707070" strokeWidth="1">
                                <rect width="12" height="688.061" rx="6" stroke="none" />
                                <rect x="0.5" y="0.5" width="11" className='path-line'  rx="5.5" fill="none" />
                            </g>
                            <g id="Rectangle_9047" data-name="Rectangle 9047" transform="translate(157 0)" fill="none" stroke="#707070" strokeWidth="1">
                                <rect width="13" height="688.061" rx="6.5" stroke="none" />
                                <rect x="0.5" y="0.5" width="12" className='path-line' rx="6" fill="none" />
                            </g>
                            <g id="Rectangle_9049" data-name="Rectangle 9049" transform="translate(314 0)" fill="none" stroke="#707070" strokeWidth="1">
                                <rect width="12" height="688.061" rx="6" stroke="none" />
                                <rect x="0.5" y="0.5" width="11" className='path-line'  rx="5.5" fill="none" />
                            </g>
                        </g>
                    </g>
                    <g id="Group_20994" data-name="Group 20994" transform="translate(0 170)">
                        <g id="Group_20992" data-name="Group 20992" opacity="0.557">
                            <rect id="Rectangle_13945" data-name="Rectangle 13945" width="63" height="63" transform="translate(960 4488)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            <path id="Path_111740" data-name="Path 111740" d="M-9671,492l17.12,17.12" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111741" data-name="Path 111741" d="M-9608,492l-16.72,16.72" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111742" data-name="Path 111742" d="M-9624.72,538.195l16.683,16.682" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111743" data-name="Path 111743" d="M-9671,554.877l16.809-16.808" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                        </g>
                        <g id="Group_20993" data-name="Group 20993" transform="translate(408)" opacity="0.557">
                            <rect id="Rectangle_13945-2" data-name="Rectangle 13945" width="63" height="63" transform="translate(960 4488)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            <path id="Path_111740-2" data-name="Path 111740" d="M-9671,492l17.12,17.12" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111741-2" data-name="Path 111741" d="M-9608,492l-16.72,16.72" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111742-2" data-name="Path 111742" d="M-9624.72,538.195l16.683,16.682" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111743-2" data-name="Path 111743" d="M-9671,554.877l16.809-16.808" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                        </g>
                        <g id="Group_20991" data-name="Group 20991" transform="translate(9957.061 3860.975)">
                            <path id="Path_111744" data-name="Path 111744" d="M0,0H437V29H0Z" transform="translate(-8980.061 644.025)" fill="#eee" stroke="#000" strokeWidth="1" />
                            <g id="Group_20990" data-name="Group 20990" transform="translate(-8979.873 644.083)">
                                <path id="Path_111735" data-name="Path 111735" d="M-8979.72,644.086v29.137l29.137-29.137v29.137l29.137-29.137v29.137l29.137-29.137" transform="translate(8979.72 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111736" data-name="Path 111736" d="M-8979.72,644.086v29.137l29.137-29.137v29.137l29.137-29.137v29.137l29.137-29.137" transform="translate(9067.13 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111737" data-name="Path 111737" d="M-8979.72,644.086v29.137l29.137-29.137v29.137l29.137-29.137v29.137l29.137-29.137" transform="translate(9154.542 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111738" data-name="Path 111738" d="M-8979.72,644.086v29.137l29.137-29.137v29.137l29.137-29.137v29.137l29.137-29.137" transform="translate(9241.952 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111739" data-name="Path 111739" d="M-8979.72,644.086v29.137l29.137-29.137v29.137l29.137-29.137v29.137l29.137-29.137v29.137" transform="translate(9329.364 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            </g>
                        </g>
                    </g>
                    <g id="Group_21189" data-name="Group 21189" transform="translate(0 405)">
                        <g id="Group_20992-2" data-name="Group 20992" opacity="0.557">
                            <rect id="Rectangle_13945-3" data-name="Rectangle 13945" width="63" height="63" transform="translate(960 4488)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            <path id="Path_111740-3" data-name="Path 111740" d="M-9671,492l17.12,17.12" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111741-3" data-name="Path 111741" d="M-9608,492l-16.72,16.72" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111742-3" data-name="Path 111742" d="M-9624.72,538.195l16.683,16.682" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111743-3" data-name="Path 111743" d="M-9671,554.877l16.809-16.808" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                        </g>
                        <g id="Group_20993-2" data-name="Group 20993" transform="translate(408)" opacity="0.557">
                            <rect id="Rectangle_13945-4" data-name="Rectangle 13945" width="63" height="63" transform="translate(960 4488)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            <path id="Path_111740-4" data-name="Path 111740" d="M-9671,492l17.12,17.12" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111741-4" data-name="Path 111741" d="M-9608,492l-16.72,16.72" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111742-4" data-name="Path 111742" d="M-9624.72,538.195l16.683,16.682" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111743-4" data-name="Path 111743" d="M-9671,554.877l16.809-16.808" transform="translate(10631 3996)" fill="none" stroke="#000" strokeWidth="1" />
                        </g>
                        <g id="Group_20991-2" data-name="Group 20991" transform="translate(9957.061 3860.975)">
                            <path id="Path_111744-2" data-name="Path 111744" d="M0,0H437V29H0Z" transform="translate(-8980.061 644.025)" fill="#eee" stroke="#000" strokeWidth="1" />
                            <g id="Group_20990-2" data-name="Group 20990" transform="translate(-8979.873 644.083)">
                                <path id="Path_111735-2" data-name="Path 111735" d="M-8979.72,644.086v29.137l29.137-29.137v29.137l29.137-29.137v29.137l29.137-29.137" transform="translate(8979.72 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111736-2" data-name="Path 111736" d="M-8979.72,644.086v29.137l29.137-29.137v29.137l29.137-29.137v29.137l29.137-29.137" transform="translate(9067.13 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111737-2" data-name="Path 111737" d="M-8979.72,644.086v29.137l29.137-29.137v29.137l29.137-29.137v29.137l29.137-29.137" transform="translate(9154.542 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111738-2" data-name="Path 111738" d="M-8979.72,644.086v29.137l29.137-29.137v29.137l29.137-29.137v29.137l29.137-29.137" transform="translate(9241.952 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111739-2" data-name="Path 111739" d="M-8979.72,644.086v29.137l29.137-29.137v29.137l29.137-29.137v29.137l29.137-29.137v29.137" transform="translate(9329.364 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    )
}
export default TollboothDesktop