import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../assets/svgs/arrowRightBlack.svg';
import { motion } from 'framer-motion';
import { logEvent } from '../analytics'; // Import the logEvent function

export default function RequestDemoButton(props) {
  const navigate = useNavigate();
  const { parentPage } = props;

  const handleSubmit = () => {
    logEvent(
      'User Engagement',
      'Demo Button Clicked',
      'Request Demo Button',
      parentPage
    );

    navigate('/contact', { state: { additionalProp: parentPage } });
  };

  return (
    <motion.div
      className="request-demo-page"
      initial={{ opacity: 0, y: 0 }}
      animate={{ opacity: 1, y: parentPage === 'About' ? 0 : 80 }}
      transition={{ duration: 0.8 }}
    >
      <div className="pure-g">
        <div className="button-parent pure-u-xl-10-24">
          <button
            className="button-container"
            type="submit"
            onClick={handleSubmit}
          >
            Request a demo
          </button>
          <ArrowRight />
        </div>
      </div>
    </motion.div>
  );
}
