import ReactGA from 'react-ga4';

const MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const initGA = () => {
  ReactGA.initialize(MEASUREMENT_ID);
};

const logPageView = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    title: document.title,
  });
};

const logEvent = (category, action, label, value) => {
  const formattedLabel = label?.value ?? label;
  const formattedValue = value?.value ?? value;

  ReactGA.event({
    category,
    action,
    label: formattedLabel,
    value: formattedValue,
  });
};

export { initGA, logPageView, logEvent };
