import { useStoryblokApi } from "@storyblok/react";
import AnimatedDiv from "../AnimatedDiv";
import { motion } from "framer-motion"
import { useEffect, useState } from "react";
import Tag from "../Blogs/Tag";
import TagGroup from "../Blogs/TagGroup";
import BlogDate from "../Blogs/BlogDate";

const BlogHome = ({ blok }) => {

    const storyblokApi = useStoryblokApi();
    const [stories, setStories] = useState();

    useEffect(() => {
        if (!stories) {
            storyblokApi.getStories({
                content_type: 'blog_post',
                version: process.env.REACT_APP_SHOW_DRAFT_CMS_CONTENT === "true" ? "draft" : "published"
            }).then((response) => {
                setStories(response.data);
            })
        }
    }, [storyblokApi, stories]);

    return (
        <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <AnimatedDiv>
                <div className="pure-g container tw-py-8 tw-justify-center">
                    <div className="tw-flex tw-flex-col tw-gap-8 tw-align-top">
                        <h1>{ blok.header }</h1>
                        <div className="tw-grid grid-cols-1 md:tw-grid-cols-2 tw-gap-8">
                            {
                                stories 
                                    ? stories.stories
                                        .sort((a, b) => new Date(b.published_at ?? b.created_at) - new Date(a.published_at ?? a.created_at))
                                        .map((story) => {
                                        if (story.content) {
                                            return <BlogPreview key={story.content._uid} story={story} />
                                        }
                                        return <></>
                                    })
                                    : <></>
                            }
                        </div>
                    </div>
                </div>
            </AnimatedDiv>
        </motion.div>
    )
};

const BlogPreview = ({ story }) => {

    const blogDate = story.published_at ? new Date(story.published_at) : new Date(story.created_at);

    return (
        <a href={`/${story.full_slug}`} className="tw-block tw-text-white tw-font-extralight tw-border-gray-400 tw-border tw-max-w-[350px]">
            <div className="tw-max-h-60 tw-overflow-hidden tw-flex tw-align-middle">
                <img className="tw-object-cover tw-w-full tw-max-h-full" src={`${story.content.preview_thumbnail.filename}/m/`} alt={story.content.preview_thumbnail.alt} />
            </div>
            <div className="tw-p-4 tw-flex tw-flex-col tw-gap-4">
                <div className="tw-flex tw-flex-row tw-justify-between tw-align-top tw-gap-4 tw-flex-nowrap">
                    <TagGroup>
                        {
                            (story.content.tags && story.content.tags.replace(' ', '')) &&
                            story.content.tags.split(' ').map(tag => (
                                <Tag key={tag} text={tag} />
                            ))
                        }
                    </TagGroup>
                    <BlogDate date={blogDate} />
                </div>
                <div>
                    <h2 className="tw-text-2xl">{story.content.title}</h2>
                    <p>{story.content.preview_headline}</p>
                </div>
            </div>
        </a>
)
}

export default BlogHome;