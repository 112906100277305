interface FreeTextFieldProps {
  placeholder?: string;
  minValue?: number;
  maxValue?: number;
  type?: string;
  currentValue: number | string;
  onChange: (value: string) => void;
}

const FreeTextField = ({
  placeholder = '',
  maxValue = 10000,
  type = 'text',
  currentValue,
  onChange,
}: FreeTextFieldProps) => {
  // Format display value with commas if it's a number
  const formatDisplayValue = (value: number | string): string => {
    if (value === '') return '';

    if (type === 'text') {
      return String(value || '');
    } else {
      // Check if the value is a valid number
      const numValue = typeof value === 'string' ? parseFloat(value) : value;
      if (!isNaN(numValue)) {
        return numValue.toLocaleString('en-US');
      }
      return String(value || '');
    }
  };

  // Handle input change and strip commas for the onChange callback
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'text') {
      onChange(e.target.value);
    } else {
      // Check that the value is less than the max value
      const strippedValue = e.target.value.replace(/,/g, '');
      const numValue = parseInt(strippedValue);

      if (numValue > maxValue) {
        onChange(maxValue.toString());
      } else {
        onChange(strippedValue);
      }
    }
  };

  return (
    <input
      placeholder={placeholder}
      type="text" // Changed to text to allow comma formatting
      value={formatDisplayValue(currentValue)}
      onChange={handleChange}
      style={{
        width: '100%',
        background: 'transparent',
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '5px',
        marginTop: '8px',
        marginBottom: '8px',
      }}
    />
  );
};

export default FreeTextField;
