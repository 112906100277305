import React, { createContext, useState, useContext, useEffect } from 'react';

interface LeadCaptureState {
  // Input Fields
  name: string;
  emailAddress: string;
  companyName: string;
  jobTitle: string;
  fleetSize: string;

  // Hygiene
  hasAcceptedTerms: boolean;

  // Submitted form
  hasSubmittedForm: boolean;
  formErrorMessage: string;
}

interface LeadCaptureContextType {
  leadCaptureState: LeadCaptureState;
  updateleadCaptureState: (newState: Partial<LeadCaptureState>) => void;
}

const LeadCaptureContext = createContext<LeadCaptureContextType | undefined>(
  undefined
);

const LOCAL_STORAGE_KEY = 'LeadCaptureState';

const defaultState: LeadCaptureState = {
  // Inputs
  name: '',
  emailAddress: '',
  companyName: '',
  jobTitle: '',
  fleetSize: '',

  // Hygiene
  hasAcceptedTerms: false,

  // Submitted form
  hasSubmittedForm: false,
  formErrorMessage: 'Please fill in all fields and accept the terms',
};

export const LeadCaptureProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [leadCaptureState, setState] = useState<LeadCaptureState>(() => {
    // Try to load the state from localStorage on initial render
    if (typeof window !== 'undefined') {
      const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
      return storedState ? JSON.parse(storedState) : defaultState;
    } else {
      return defaultState;
    }
  });

  useEffect(() => {
    // Save to localStorage whenever the state changes
    if (typeof window !== 'undefined') {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(leadCaptureState));
    }
  }, [leadCaptureState]);

  const updateleadCaptureState = (newState: Partial<LeadCaptureState>) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <LeadCaptureContext.Provider
      value={{ leadCaptureState, updateleadCaptureState }}
    >
      {children}
    </LeadCaptureContext.Provider>
  );
};

export const useLeadCaptureContext = (): LeadCaptureContextType => {
  const context = useContext(LeadCaptureContext);
  if (!context) {
    throw new Error(
      'useLeadCaptureContext must be used within a ReductionsCalculatorProvider'
    );
  }
  return context;
};
