const VimeoVideo = ({ blok }) => {

    const url = blok.url
        .replace("vimeo.com/", "player.vimeo.com/video/");

    return (
        <div className="tw-flex tw-justify-center tw-w-full">
            <iframe 
                className="tw-aspect-video"
                width="100%"
                title={`Vimeo video player - ${url}`}
                src={url} 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowFullScreen={true}>   
            </iframe>
        </div>
    )
}

export default VimeoVideo;