import React, { useEffect } from 'react';

// Hooks
import ScreenDetect from '../hooks/ScreenDetect';

// Components
import Showcase from '../components/Showcase';
import Preloader from '../components/Preloader';
import Usages from '../components/Hompage/Usages';
import HomeLayered from '../components/Hompage/Layered';
import HeroSection from '../components/Hompage/HeroSection';
import Highlighter from '../components/Hompage/Highlighter';
import TabNavigator from '../components/Hompage/TabNavigator';
import QuickNavigator from '../components/Hompage/QuickNavigator';
import HomeLayeredSmall from '../components/Hompage/LayeredSmall';
import PolicyFramework from '../components/Hompage/PolicyFramework';

// Sections
import ReductionPotential from './Sections/ReductionsPotentialCalculator';

function HomePage(props) {
  const isPreLoaderLoaded = props.isPreLoaderLoaded;

  const { isMobile, isTab } = ScreenDetect();

  // Add useEffect to handle cursor visibility
  useEffect(() => {
    // Create or update style element
    const styleElement = document.createElement('style');
    styleElement.id = 'cursor-style';

    if (!isPreLoaderLoaded) {
      styleElement.textContent = `
        * {
          cursor: none !important;
        }
      `;
    } else {
      styleElement.textContent = '';
    }

    // Add style element to document head
    document.head.appendChild(styleElement);

    // Cleanup function to remove style when component unmounts
    return () => {
      const existingStyle = document.getElementById('cursor-style');
      if (existingStyle) {
        existingStyle.remove();
      }
    };
  }, [isPreLoaderLoaded]); // Only re-run when isPreLoaderLoaded changes

  return isPreLoaderLoaded ? (
    <>
      <HeroSection />

      {/* Calculator */}
      <ReductionPotential />

      <TabNavigator />
      <Highlighter />
      {!isMobile && !isTab && <HomeLayered />}
      {(isMobile || isTab) && <HomeLayeredSmall />}
      <PolicyFramework />
      <Usages />
      <QuickNavigator />
      <div className="showcases">
        <Showcase
          className="investors & partners"
          backgroundColor="#7C7C7C"
          isMobile={isMobile}
        />
        <Showcase
          className="awards"
          backgroundColor="#434343"
          isMobile={isMobile}
        />
        <Showcase
          className="standards"
          backgroundColor="#242424"
          isMobile={isMobile}
        />
      </div>
    </>
  ) : (
    <Preloader />
  );
}
export default HomePage;
