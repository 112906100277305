import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import ga from "../helpers/ga";

function GoogleAnalyticsProvider({ children, setTrackingEnabled }) {
  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get("cookieAcceptance") === "accepted") {
      ga.allowTracking();
      setTrackingEnabled(true);
    }
  }, [setTrackingEnabled]);

  return <>{children}</>;
}

export default GoogleAnalyticsProvider;
