import React from 'react'
function TollboothMobile(props) {
    const switchTrafficLight = props.switchTrafficLight;
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="361" height="281.682" viewBox="0 0 361 281.682">
                <g id="Group_25198" data-name="Group 25198" transform="translate(6019 9935)">
                    <rect id="Rectangle_15645" data-name="Rectangle 15645" width="361" height="281.682" transform="translate(-6019 -9935)" fill="#f0f0f0" />
                    <g id="Group_25191" data-name="Group 25191" transform="translate(-5917.861 -9918.576)">
                        <rect id="Rectangle_9054" data-name="Rectangle 9054" width="42.818" height="21.409" rx="10.704" transform="translate(0 0)" />
                        <ellipse id="Ellipse_621" data-name="Ellipse 621" cx="8.859" cy="8.49" rx="8.859" ry="8.49" transform="translate(2.953 2.215)" fill={!switchTrafficLight ? '#c61d85' : 'none' } stroke={switchTrafficLight ? '#707070' : 'none' } />
                        <g id="Ellipse_622" data-name="Ellipse 622" transform="translate(22.147 2.215)" fill="none" stroke={!switchTrafficLight ? '#707070' : 'none' } strokeWidth="1">
                            <ellipse cx="8.859" cy="8.49" rx="8.859" ry="8.49" stroke="none" />
                            <ellipse cx="8.859" cy="8.49" rx="8.359" ry="7.99"  fill={switchTrafficLight ? '#c61d85' : 'none' } />
                        </g>
                    </g>
                    <g id="Group_25192" data-name="Group 25192" transform="translate(-5917.861 -9708.576)">
                        <rect id="Rectangle_9054-2" data-name="Rectangle 9054" width="42.818" height="21.409" rx="10.704" transform="translate(0 0)" />
                        <ellipse id="Ellipse_621-2" data-name="Ellipse 621" cx="8.859" cy="8.49" rx="8.859" ry="8.49" transform="translate(2.953 2.215)" fill={!switchTrafficLight ? '#c61d85' : 'none' } stroke={switchTrafficLight ? '#707070' : 'none' } />
                        <g id="Ellipse_622-2" data-name="Ellipse 622" transform="translate(22.147 2.215)" fill="none" stroke={!switchTrafficLight ? '#707070' : 'none' } strokeWidth="1">
                            <ellipse cx="8.859" cy="8.49" rx="8.859" ry="8.49" stroke="none" />
                            <ellipse cx="8.859" cy="8.49" rx="8.359" ry="7.99"  fill={switchTrafficLight ? '#c61d85' : 'none' } />
                        </g>
                    </g>
                    <g id="Group_25193" data-name="Group 25193" transform="translate(-5801.219 -9918.576)">
                        <g id="Group_19763" data-name="Group 19763" transform="translate(0 0)">
                            <rect id="Rectangle_9054-3" data-name="Rectangle 9054" width="42.818" height="21.409" rx="10.704" />
                            <g id="Ellipse_621-3" data-name="Ellipse 621" transform="translate(2.953 2.215)" fill="none" stroke={!switchTrafficLight ? '#707070' : 'none' } strokeWidth="1">
                                <ellipse cx="8.859" cy="8.49" rx="8.859" ry="8.49" stroke="none" />
                                <ellipse cx="8.859" cy="8.49" rx="8.359" ry="7.99" fill={switchTrafficLight ? '#ff7c7c' : 'none' } />
                            </g>
                            <ellipse id="Ellipse_622-3" data-name="Ellipse 622" cx="8.859" cy="8.49" rx="8.859" ry="8.49" transform="translate(22.147 2.215)"  fill={!switchTrafficLight ? '#ff7c7c' : 'none' } stroke={switchTrafficLight ? '#707070' : 'none' } />
                        </g>
                    </g>
                    <g id="Group_25194" data-name="Group 25194" transform="translate(-5801.219 -9708.576)">
                        <g id="Group_19763-2" data-name="Group 19763" transform="translate(0 0)">
                            <rect id="Rectangle_9054-4" data-name="Rectangle 9054" width="42.818" height="21.409" rx="10.704" />
                            <g id="Ellipse_621-4" data-name="Ellipse 621" transform="translate(2.953 2.215)" fill="none" stroke={!switchTrafficLight ? '#707070' : 'none' } strokeWidth="1">
                                <ellipse cx="8.859" cy="8.49" rx="8.859" ry="8.49" stroke="none" />
                                <ellipse cx="8.859" cy="8.49" rx="8.359" ry="7.99"  fill={switchTrafficLight ? '#ff7c7c' : 'none' } />
                            </g>
                            <ellipse id="Ellipse_622-4" data-name="Ellipse 622" cx="8.859" cy="8.49" rx="8.859" ry="8.49" transform="translate(22.147 2.215)"  fill={!switchTrafficLight ? '#ff7c7c' : 'none' } stroke={switchTrafficLight ? '#707070' : 'none' } />
                        </g>
                    </g>
                    <g id="Group_25197" data-name="Group 25197" transform="translate(-6019 -9881.983)">
                        <g id="Group_25195" data-name="Group 25195" transform="translate(60.536 4.074)">
                            <g id="Rectangle_15646" data-name="Rectangle 15646" transform="translate(0 0)" fill="none" stroke="#707070" strokeWidth="1">
                                <rect width="8.859" height="156.854" rx="4.429" stroke="none" />
                                <rect x="0.5" y="0.5" width="7.859" height="155.854" rx="3.929" fill="none" />
                            </g>
                            <g id="Rectangle_15647" data-name="Rectangle 15647" transform="translate(115.904 0)" fill="none" stroke="#707070" strokeWidth="1">
                                <rect width="9.597" height="156.854" rx="4.799" stroke="none" />
                                <rect x="0.5" y="0.5" width="8.597" height="155.854" rx="4.299" fill="none" />
                            </g>
                            <g id="Rectangle_15648" data-name="Rectangle 15648" transform="translate(231.808 0)" fill="none" stroke="#707070" strokeWidth="1">
                                <rect width="8.859" height="156.854" rx="4.429" stroke="none" />
                                <rect x="0.5" y="0.5" width="7.859" height="155.854" rx="3.929" fill="none" />
                            </g>
                        </g>
                    </g>
                    <g id="Group_25196" data-name="Group 25196" transform="translate(-6012.356 -9841.243)">
                        <g id="Group_20992" data-name="Group 20992" transform="translate(0 0)" opacity="0.557">
                            <rect id="Rectangle_13945" data-name="Rectangle 13945" width="46" height="46" transform="translate(0.356 0.144)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            <path id="Path_111740" data-name="Path 111740" d="M-9671,492l12.639,12.638" transform="translate(9671 -492)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111741" data-name="Path 111741" d="M-9612.377,492l-12.343,12.344" transform="translate(9658.886 -492)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111742" data-name="Path 111742" d="M-9624.72,538.195l12.315,12.315" transform="translate(9658.886 -504.092)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111743" data-name="Path 111743" d="M-9671,550.477l12.408-12.409" transform="translate(9671 -504.059)" fill="none" stroke="#000" strokeWidth="1" />
                        </g>
                        <g id="Group_20993" data-name="Group 20993" transform="translate(301.202 0)" opacity="0.557">
                            <rect id="Rectangle_13945-2" data-name="Rectangle 13945" width="46" height="46" transform="translate(0.154 0.144)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            <path id="Path_111740-2" data-name="Path 111740" d="M-9671,492l12.639,12.638" transform="translate(9671 -492)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111741-2" data-name="Path 111741" d="M-9612.377,492l-12.343,12.344" transform="translate(9658.886 -492)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111742-2" data-name="Path 111742" d="M-9624.72,538.195l12.315,12.315" transform="translate(9658.886 -504.092)" fill="none" stroke="#000" strokeWidth="1" />
                            <path id="Path_111743-2" data-name="Path 111743" d="M-9671,550.477l12.408-12.409" transform="translate(9671 -504.059)" fill="none" stroke="#000" strokeWidth="1" />
                        </g>
                        <g id="Group_20991" data-name="Group 20991" transform="translate(12.55 12.55)">
                            <path id="Path_111744" data-name="Path 111744" d="M0,0H322.611V21.409H0Z" transform="translate(0 0)" fill="#eee" stroke="#000" strokeWidth="1" />
                            <g id="Group_20990" data-name="Group 20990" transform="translate(0.138 0.043)">
                                <path id="Path_111735" data-name="Path 111735" d="M-8979.72,644.086V665.6l21.51-21.51V665.6l21.51-21.51V665.6l21.51-21.51" transform="translate(8979.72 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111736" data-name="Path 111736" d="M-8979.72,644.086V665.6l21.51-21.51V665.6l21.51-21.51V665.6l21.51-21.51" transform="translate(9044.25 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111737" data-name="Path 111737" d="M-8979.72,644.086V665.6l21.51-21.51V665.6l21.51-21.51V665.6l21.51-21.51" transform="translate(9108.78 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111738" data-name="Path 111738" d="M-8979.72,644.086V665.6l21.51-21.51V665.6l21.51-21.51V665.6l21.51-21.51" transform="translate(9173.311 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                <path id="Path_111739" data-name="Path 111739" d="M-8979.72,644.086V665.6l21.51-21.51V665.6l21.51-21.51V665.6l21.51-21.51V665.6" transform="translate(9237.842 -644.086)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    )
}
export default TollboothMobile