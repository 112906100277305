import React from 'react'
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRight } from '../../assets/svgs/arrowRight.svg';
import AnimatedDiv from "../AnimatedDiv"
import ScreenDetect from '../../hooks/ScreenDetect';
import { assets } from '../../assets/aws-assets';
import RequestDemoButton from '../../views/requestDemoButton';
function QuickNavigator() {
    const { isMobile, isTab, isLaptop } = ScreenDetect();
    const navigate = useNavigate();
    return (
        <>
            <div className='container container-home homepage-quick-navigator pure-g'>
                <div className={`left ${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-12-24' : ''}${isLaptop ? ' pure-u-15-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-12-24" : ''}`}>
                    <div style={{transform:'translateY(-225px)'}} className='quicknavigator-request-demo'>
                        <RequestDemoButton parentPage='Landing page' />
                    </div>
                    <div className='pure-u-1 title-row'>
                        <AnimatedDiv>
                            <h4><span>Why guess, when you can be certain?</span><br />Turn your net-zero goals into action today.</h4>
                        </AnimatedDiv>
                    </div>
                    <AnimatedDiv>
                        <a className={`navigator-cards link ${isMobile ? ' pure-g' : ''}${isTab ? ' pure-g' : ''}${isLaptop ? ' pure-u-24-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-24-24" : ''}`}>
                            <div className={`navigator-single link ${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-24-24' : ''}${isLaptop ? ' pure-u-24-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-12-24" : ''}`} onClick={() => { navigate("/contact") }}>
                                <h3>Contact us</h3>
                                <ArrowRight />
                            </div>
                            <div className={`navigator-single link ${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-24-24' : ''}${isLaptop ? ' pure-u-24-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-12-24" : ''}`} onClick={() => { navigate("/team") }}>
                                <h3>Meet the people <br />behind <span>Clearly</span></h3>
                                <ArrowRight />
                            </div>
                        </a>
                    </AnimatedDiv>
                </div>
                {!isMobile &&
                    <div className={`${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-11-24' : ''}${isLaptop ? ' pure-u-8-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-6-24" : ''} paths right`}>
                        <div className='path path-1'>
                            <img src={assets.carWithTick} alt="car" loading='lazy'></img>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
export default QuickNavigator