export const assets = {
    'mobileCarGIF': `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/gif/Clearly-mobile-car-lanes-v3.gif`,
    'blueMobile': `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Blue-Mobile.png`,
    'blue': `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Blue.png`,
    'branchingAlternative' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/branching-mobile.png`,
    'branching' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/branching.png`,
    'carWithTick' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Car_with_tick.png`,
    'carWithBlackBackground' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Car-with-black-background.png`,
    'Car' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Car.png`,
    'background1': `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/jared-murray-NSuufgf-BME-unsplash.jpg`,
    'background2' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/deva-darshan-2XUfjLp7HYY-unsplash.jpg`,
    'background3' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/pexels-kelly-3997067.jpg`,
    'greenMobile' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Green-Mobile.png`,
    'green' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Green.png`,
    'grid' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Grid.png`,
    'barrierMobile' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Mobile-Barrier.png`,
    'mobileGrid' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Mobile-Grid.png`,
    'pageNotFound' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/page-not-found.png`,
    'dataFusionMobile' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Red-Mobile.png`,
    'dataFusion':`https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Red.png`,
    'truck' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Truck-with-black-background.png`,
    'truckPathRight' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Truck.png`,
    'vanWithTick' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Van_with_tick.png`, 
    'vanWithBlackBG' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Van-with-black-background.png`,
    'van' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Van.png`,
    'yellowMobile': `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Yellow-Mobile.png`,
    'yellow': `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/img/backgrounds/Yellow.png`,
    'mainIllustration' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/svgs/ABOUT-US-OVERVIEW-DESKTOP-NOLOGO.svg`,
    'mainIllustrationAlternative':`https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/svgs/ABOUT-US-OVERVIEW-MOBILE-NOLOGO.svg`,
    'tollBooth' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/svgs/Back-of-tollbooth.svg`,
    'barrier': `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/svgs/barrier.svg`,
    'worldMap': `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/svgs/ABOUT-US-WORLD-COMBINED-DESKTOP-v3.svg`,
    'worldMobileMap': `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/svgs/ABOUT-US-WORLD-MAP-COMBINED-MOBILE-v3.svg`,
    'angleDown' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/svgs/angleDown.svg`,
    'arrowRight' : `https://web-homepage-assets.s3.amazonaws.com/${process.env.REACT_APP_ENV}/svgs/arrowRight.svg`
}