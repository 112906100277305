// Components
import SelectInput from './SelectInput';
import WhiteFieldLabel from '../Typography/WhiteFieldLabel';

// Types
import { LabelValuePair } from '../../context/calculators/reductionsCalculator';

interface DropDownOptionProps {
  title: string;
  required?: boolean;
  selectedOption: LabelValuePair;
  options: LabelValuePair[];
  useDarkBorders?: boolean;
  disabled?: boolean;
  onSelect?: (inputValue: LabelValuePair) => void;
}

const DropDownOption = ({
  title,
  required,
  selectedOption,
  options,
  useDarkBorders,
  disabled,
  onSelect,
}: DropDownOptionProps) => {
  return (
    <div style={{ marginBottom: '30px' }}>
      <WhiteFieldLabel
        labelText={title}
        required={required}
        isValid={!!selectedOption.value}
      />
      <SelectInput
        selectedOption={selectedOption}
        options={options}
        onSelect={onSelect ? onSelect : () => {}}
        useDarkBorders={useDarkBorders}
        disabled={disabled}
      />
    </div>
  );
};

export default DropDownOption;
