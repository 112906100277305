import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion"
import PathRight from '../components/Hompage/PathRight';
import ScreenDetect from '../hooks/ScreenDetect';
export default function DefaultPage(props) {
    const isPage = props.pageName;
    const { isMobile, isTab } = ScreenDetect();
    const [pageData, setPageData] = useState([]);
    useEffect(() => {
        fetch('datasets/defaultPageData.json')
            .then(response => response.json())
            .then(data => setPageData(data.pagedata));
    }, []);
    return (
        <>
            {isPage === "privacy-policy" &&
                <motion.div className='container default-page pure-g' initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
                    {pageData.map((page) => (
                        page.name === isPage && (
                            <div key={page.name} className='pure-u-lg-12-24 left'>
                                <div className='main-content'>
                                    <h1>{page.title}</h1>
                                    <div className='content pure-u-xl-14-24' dangerouslySetInnerHTML={{ __html: page.caption }}></div>
                                </div>
                                <div className='additional-content'>
                                    {page.additional.map((extra, index) => (
                                        <div key={extra.title} className={`item item${index + 1}`}>
                                            <h3 className='pure-u-xl-14-24'>{extra.title}</h3>
                                            <div className='content pure-u-xl-14-24' dangerouslySetInnerHTML={{ __html: extra.caption }}></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )))}
                    {(!isMobile && !isTab) &&
                        <div className='paths pure-u-lg-8-24 right'>
                            <PathRight />
                        </div>
                    }
                </motion.div>
            }
        </>
    )
}
