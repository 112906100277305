const BlogAuthor = ({ blok }) => {
    return (
        <div className="tw-flex tw-flex-row tw-gap-3 tw-items-center">
            <div className="tw-w-14 tw-h-14 tw-min-w-14 tw-min-h-14 tw-overflow-clip tw-rounded-full">
                <img className="tw-object-cover tw-w-full tw-h-full" src={blok.profile_photo.filename} alt={blok.profile_photo.alt} />
            </div>
            <div className="tw-font- tw-flex tw-flex-col tw-gap-0 tw-align-top">
                <p className="tw-text-white tw-text-lg tw-font-bold">{blok.name}</p>
                <p className="tw-text-white">{blok.job_title}</p>
            </div>
        </div>
    )
}

export default BlogAuthor;