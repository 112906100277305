import { useEffect, useState } from 'react'
function GetScroll() {
    const [headerTopOnHome, setHeaderToHome] = useState(0);
    useEffect(() => {
        const handleScrollHeader = () => {
            const position = window.pageYOffset;
            setHeaderToHome(position)
        };
        window.addEventListener('scroll', handleScrollHeader, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScrollHeader);
        };
    }, [])
    return (
        headerTopOnHome
    )
}
export default GetScroll