import React, { useEffect, useState } from 'react'
import ImgCaption from '../../components/ImgCaption';
import AnimatedDiv from "../../components/AnimatedDiv"
import ScreenDetect from '../../hooks/ScreenDetect';
import { assets } from '../../assets/aws-assets';
function Usages() {
    const { isMobile, isTab, isLaptop } = ScreenDetect();
    const [listUsages, setUsages] = useState([]);
    useEffect(() => {
        fetch('./datasets/usages.json')
            .then(response => response.json())
            .then(data => setUsages(data.usages));
    }, []);
    return (
        <>
            <div className='container container-home homepage-usages pure-g'>
                <div className={`left ${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-12-24' : ''}${isLaptop ? ' pure-u-15-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-18-24" : ''}`}>
                    <div className='pure-u-1 title-row'>
                        <AnimatedDiv>
                            <h3>Customers are using Clearly to</h3>
                        </AnimatedDiv>
                    </div>
                    <div className='pure-u-24-24 cards'>
                        {listUsages.map((item, index) => (
                            <div key={index} className={`img-caption-single ${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-20-24' : ''}${isLaptop ? ' pure-u-11-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-4-24" : ''}`}>
                                <AnimatedDiv>
                                    <ImgCaption img={item.image} caption={item.caption} />
                                </AnimatedDiv>
                            </div>
                        ))}
                    </div>
                </div>
                {!isMobile &&
                    <div className={`${isMobile ? ' pure-u-24-24' : ''}${isTab ? ' pure-u-11-24' : ''}${isLaptop ? ' pure-u-8-24' : ''}${!isMobile && !isTab && !isLaptop ? " pure-u-6-24" : ''} paths right`}>
                        <div className='path path-1'>
                            <img src={assets.vanWithTick} alt="van" loading='lazy'></img>
                        </div>
                        <div className='toll-booth'>
                            <img src={assets.tollBooth} alt='tollbooth' loading='lazy'></img>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
export default Usages