import {
  emissions_price,
  fuel_efficiency,
  fuel_emissions,
  fuel_price,
  fuel_reduction_options,
  initiative_distance,
  initiative_prices,
  INITIATIVES,
} from './reductionsPotentialData';

// Interfaces
export interface VehicleShape {
  vehicle_number: number;
  vehicle_type: string;
  distance: number;
  region: string;
  fuel_type: string;
  oil_type: string;
  wheel_type: string;
  tyre_type: string;
  tyre_pressure: string;
  aerodynamics: string;
}

interface CostResult {
  financial: number;
  emissions: number;
  total: number;
}

// Interface for costs object structure
interface Costs {
  emissions: number;
  financial: number;
  total: number;
}

// Interface for the differences result
interface CostDifferences {
  financial_change: number;
  emissions_change: number;
  financial_change_percent: number;
  emissions_change_percent: number;
  financial_total_change_percent: number;
}

//

// Function to extract optimal vehicles
export function extractOptimalVehicles(
  inputVehicles: VehicleShape[]
): VehicleShape[] {
  const optimalVehicles: VehicleShape[] = inputVehicles.map((vehicle) => ({
    vehicle_number: vehicle.vehicle_number,
    vehicle_type: vehicle.vehicle_type,
    distance: vehicle.distance,
    region: vehicle.region,
    fuel_type: 'b20',
    oil_type: 'synthetic_5W30',
    wheel_type: 'aluminium',
    tyre_type: 'A',
    tyre_pressure: 'good',
    aerodynamics: 'good',
  }));

  return optimalVehicles;
}

//

// Main calculation function
export function calculateAllCosts(inputVehicles: VehicleShape[]): CostResult {
  // Using reduce instead of a for loop for a more functional approach
  const totals = inputVehicles.reduce(
    (acc, vehicle) => {
      const fuel = calculateFuel(vehicle);
      return {
        cost: acc.cost + calculateCost(fuel, vehicle),
        emissions: acc.emissions + calculateEmissions(fuel, vehicle.fuel_type),
      };
    },
    { cost: 0, emissions: 0 }
  );

  // Construct the final result
  const costs: CostResult = {
    financial: totals.cost,
    emissions: totals.emissions, // Emissions are in KG
    total: addCarbonCosts(
      totals.cost,
      totals.emissions,
      inputVehicles[inputVehicles.length - 1]?.region || ''
    ),
  };

  return costs;
}

//

// Individual calculations

function calculateFuel(vehicleCharacteristics: VehicleShape): number {
  const v = vehicleCharacteristics;
  const fuelReduction = fuel_reduction_options[v.vehicle_type];

  // Calculate base fuel consumption
  const fuelBase =
    fuel_efficiency[v.vehicle_type][v.fuel_type] *
    v.distance *
    v.vehicle_number;

  // Calculate cumulative fuel reductions
  const fuelReductions = INITIATIVES.reduce((accumulator, initiative) => {
    switch (initiative) {
      case 'oil':
        return accumulator * fuelReduction.oil[v.oil_type];
      case 'wheel':
        return accumulator * fuelReduction.wheel[v.wheel_type];
      case 'tyre_type':
        return accumulator * fuelReduction.tyre_type[v.tyre_type];
      case 'tyre_pressure':
        return accumulator * fuelReduction.tyre_pressure[v.tyre_pressure];
      case 'aerodynamics':
        return accumulator * fuelReduction.aerodynamics[v.aerodynamics];
      default:
        return accumulator;
    }
  }, 1);

  // Calculate final fuel consumption
  const fuel = fuelBase * fuelReductions;
  return fuel;
}

function calculateCost(
  fuelLiters: number,
  vehicleCharacteristics: VehicleShape
): number {
  const v = vehicleCharacteristics;
  let costs = fuel_price[v.region][v.fuel_type] * fuelLiters;

  // Using an object to map initiatives to their cost calculations
  const initiativeCostCalculators = {
    wheel: () => initiative_prices[v.vehicle_type].wheel[v.wheel_type],

    oil: () => {
      const yearlyReplacementOil =
        v.distance / initiative_distance[v.vehicle_type].oil[v.oil_type];
      return (
        initiative_prices[v.vehicle_type].oil[v.oil_type] * yearlyReplacementOil
      );
    },

    tyre_type: () => {
      const yearlyReplacementTyre =
        v.distance / initiative_distance[v.vehicle_type].tyre_type[v.tyre_type];
      return (
        initiative_prices[v.vehicle_type].tyre_type[v.tyre_type] *
        yearlyReplacementTyre
      );
    },

    tyre_pressure: () =>
      initiative_prices[v.vehicle_type].tyre_pressure[v.tyre_pressure],

    aerodynamics: () =>
      initiative_prices[v.vehicle_type].aerodynamics[v.aerodynamics],
  };

  // Calculate additional costs for each active initiative
  INITIATIVES.forEach((initiative) => {
    if (initiative in initiativeCostCalculators) {
      costs +=
        initiativeCostCalculators[
          initiative as keyof typeof initiativeCostCalculators
        ]();
    }
  });

  return costs;
}

function calculateEmissions(fuelLiters: number, fuelType: string): number {
  const emissionFactor = fuel_emissions[fuelType];
  return fuelLiters * emissionFactor;
}

function addCarbonCosts(
  financialCost: number,
  emissionsKg: number,
  region: string
): number {
  const carbonCost = emissionsKg * emissions_price[region];
  return financialCost + carbonCost;
}

function calculatePercentageChange(
  newValue: number,
  originalValue: number
): number {
  if (originalValue === 0) {
    return newValue === 0 ? 0 : 100; // Handle division by zero case
  }
  return ((newValue - originalValue) / Math.abs(originalValue)) * 100;
}

export function calculateDifferences(
  costsOriginal: Costs,
  costsOptimal: Costs
): CostDifferences {
  const emissionsChangePercent = calculatePercentageChange(
    costsOptimal.emissions,
    costsOriginal.emissions
  );

  const financialChangePercent = calculatePercentageChange(
    costsOptimal.financial,
    costsOriginal.financial
  );

  const financialTotalChangePercent = calculatePercentageChange(
    costsOptimal.total,
    costsOriginal.total
  );

  //

  // Construct the final result
  const financial_change = costsOptimal.financial - costsOriginal.financial;
  const emissions_change = costsOptimal.emissions - costsOriginal.emissions;

  const differences: CostDifferences = {
    financial_change: financial_change, // Financial change in currency
    emissions_change: emissions_change, // Emissions change in KG
    financial_change_percent: financialChangePercent,
    emissions_change_percent: emissionsChangePercent,
    financial_total_change_percent: financialTotalChangePercent,
  };

  return differences;
}
