// Contexts
import { logEvent } from '../../analytics';
import {
  CURRENCY_OPTIONS,
  FUEL_TYPE_OPTIONS,
  LabelValuePair,
  REGION_OPTIONS,
  UNIT_OPTIONS,
  useReductionsCalculatorsContext,
  VEHICLE_TYPE_OPTIONS,
} from '../../context/calculators/reductionsCalculator';

// Hooks
import ScreenDetect from '../ScreenDetect';

// Data
import { INPUT_VEHICLES } from './inputDemo';

// Helpers
import {
  calculateAllCosts,
  calculateDifferences,
  extractOptimalVehicles,
  VehicleShape,
} from './reductionsPotentialHelpers';

interface ReductionPotentialData {
  // Input Fields
  region: LabelValuePair;
  unit: LabelValuePair;
  currency: LabelValuePair;
  vehicleType: LabelValuePair;
  fuelType: LabelValuePair;
  numberOfVehicles: number;
  annualDistancePerVehicle: number;

  // Input options
  regionOptions: LabelValuePair[];
  unitOptions: LabelValuePair[];
  currencyOptions: LabelValuePair[];
  vehicleTypeOptions: LabelValuePair[];
  fuelTypeOptions: LabelValuePair[];

  // Output Fields
  costReduction: number;
  emissionsReduction: number;
  costReductionPercentage: string;
  emissionsReductionPercentage: string;

  // Calculator meta
  reductionsCalculatorStep: number;
  isCalculatingReductionPotential: boolean;
}

interface ReductionPotentialOperations {
  // Update Inputs
  updateInputValue: (
    inputField: string,
    inputValue: string | LabelValuePair | number
  ) => void;

  // Calculate Reduction Potential
  onCalculateReductionPotential: () => void;

  // Navigate through steps
  onGoToPreviousStep: () => void;
}

interface ReductionPotentialResponse {
  data: ReductionPotentialData;
  operations: ReductionPotentialOperations;
}

const useReductionPotential = (): ReductionPotentialResponse => {
  const pageLoadTime = Date.now();

  // Hooks
  const { isMobile } = ScreenDetect();

  //

  // State
  const { reductionsCalculatorsState, updateReductionsCalculatorsState } =
    useReductionsCalculatorsContext();

  //

  // Variables
  const {
    // Input Fields
    region,
    unit,
    currency,

    vehicleType,
    fuelType,
    numberOfVehicles,
    annualDistancePerVehicle,

    // Outputs
    costReduction = 0,
    emissionsReduction = 0,
    costReductionPercentage,
    emissionsReductionPercentage,

    // Calculator meta
    reductionsCalculatorStep,
    isCalculatingReductionPotential,
  } = reductionsCalculatorsState || {};

  //

  // Operations
  const updateInputValue = (
    inputField: string,
    inputValue: string | LabelValuePair | number
  ) => {
    // Update the state based on the input
    updateReductionsCalculatorsState({
      [inputField]: inputValue,
    });
  };

  const onCalculateReductionPotential = () => {
    if (pageLoadTime) {
      const elapsedTime = Date.now() - pageLoadTime;
      logEvent(
        'Page Time (Calculator)',
        'Time Before Calculate',
        'Elapsed Time',
        elapsedTime
      );
    }

    logEvent(
      'Demo Interaction',
      'Calculate Button Clicked',
      'Calculate Button'
    );

    // Show is calculating
    updateReductionsCalculatorsState({
      isCalculatingReductionPotential: true,
    });

    //

    // Calculate the reduction potential
    const MI_TO_KM_RATIO = 1.60934;
    const convertedAnnualDistance =
      unit.value === 'km'
        ? annualDistancePerVehicle
        : annualDistancePerVehicle * MI_TO_KM_RATIO;

    const inputVehicles: VehicleShape[] = [
      {
        // Use the defaults from the Notebook vehicle
        ...INPUT_VEHICLES[0],
        vehicle_number: numberOfVehicles,
        vehicle_type: vehicleType.value,
        fuel_type: fuelType.value,
        distance: convertedAnnualDistance,
        region: region.value,
      },
    ];

    const optimalVehicles = extractOptimalVehicles(inputVehicles);

    const costs_original = calculateAllCosts(inputVehicles);
    const costs_optimal = calculateAllCosts(optimalVehicles);

    const output = calculateDifferences(costs_original, costs_optimal);

    const {
      emissions_change_percent = 0,
      financial_total_change_percent = 0,
      emissions_change,
      financial_change,
    } = output || {};

    const costReduction = financial_change.toFixed(0);
    const emissionsReduction = (emissions_change / 1000).toFixed(0); // Convert to tonnes
    const costReductionPercentage = financial_total_change_percent.toFixed(0);
    const emissionsReductionPercentage = emissions_change_percent.toFixed(0);

    //

    // Set results, hide is calculating and go to step 1
    updateReductionsCalculatorsState({
      costReduction: Number(costReduction) * -1,
      emissionsReduction: Number(emissionsReduction) * -1,
      isCalculatingReductionPotential: false,
      reductionsCalculatorStep: 1,
      costReductionPercentage,
      emissionsReductionPercentage,
    });

    // Scroll the div with id 'reductions-output' into view on mobile
    if (isMobile) {
      const element = document.getElementById('calculator-description');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  };

  const onGoToPreviousStep = () => {
    if (reductionsCalculatorStep > 0) {
      updateReductionsCalculatorsState({
        reductionsCalculatorStep: reductionsCalculatorStep - 1,
      });
    }
  };

  return {
    data: {
      // Input Fields
      region,
      unit,
      currency,

      vehicleType,
      fuelType,
      numberOfVehicles,
      annualDistancePerVehicle,

      // Input options
      regionOptions: REGION_OPTIONS,
      unitOptions: UNIT_OPTIONS,
      currencyOptions: CURRENCY_OPTIONS,
      vehicleTypeOptions: VEHICLE_TYPE_OPTIONS,
      fuelTypeOptions: FUEL_TYPE_OPTIONS,

      // Reduction Potential
      costReduction,
      emissionsReduction,
      costReductionPercentage,
      emissionsReductionPercentage,

      // Calculator meta
      reductionsCalculatorStep,
      isCalculatingReductionPotential,
    },
    operations: {
      // Update
      updateInputValue,

      // Calculate Reduction Potential
      onCalculateReductionPotential,

      // Navigate through steps
      onGoToPreviousStep,
    },
  };
};

export default useReductionPotential;
